<template>

  <div class="payment-info">

    <b-row>
      <b-col sm="12 header">
        <h3>Payment Information</h3>
      </b-col>
    </b-row>

    <b-row class="payment-padded-row">
      <b-col sm="12">
        <label>Credit or Debit Card</label>
        <div ref="card" class="form-control card-entry"></div>
        <p class="text-danger" v-if="message">{{message}}</p>
        <small class="text-danger" v-if="showErrors && !message && errors.includes('cardHolder.card')">Please provide a valid credit or debit card</small>
      </b-col>
    </b-row>

    <b-row class="payment-padded-row">
      <b-col sm="4" role="group">
        <b-form-group>
          <label>Name on Card</label>
          <b-form-input
            autofocus
            v-model="cardHolder.fullName"
            type="text"
            placeholder=""
            :state="fullName"
          ></b-form-input>
          <small class="text-danger" v-if="showErrors && errors.includes('cardHolder.fullName')">Please provide the cardholder name</small>
        </b-form-group>

      </b-col>

      <b-col sm="4" role="group">

        <b-form-group>
          <label>Phone Number</label>
          <b-form-input
            autofocus
            v-model="cardHolder.phone"
            type="tel"
            placeholder=""
            :state="phone"
          ></b-form-input>
          <small class="text-danger" v-if="showErrors && errors.includes('cardHolder.phone')">Please provide the cardholder phone</small>
        </b-form-group>

      </b-col>

      <b-col sm="4" role="group">
        <b-form-group>
          <label>Coupon Code</label>
          <b-form-input
            autofocus
            v-model="cardHolder.coupon"
            type="text"
            placeholder=""
          ></b-form-input>

        </b-form-group>

      </b-col>

    </b-row>

  </div>

</template>

<script>

'use strict'

import { actions } from '@/services/store';

export default {

  name: 'PaymentInfo',

  data() {

    return {
      message:'',
      card:false,
      valid:false,
      cardHolder: {
        fullName:'',
        phone:'',
        coupon:''
      },
    }

  },

  async created() {

    try {

      this.cardHolder.fullName = this.user.fullname || ''

      const key = await actions.subscriptionKey();

      this.stripe = Stripe(key.data);
      const elements = this.stripe.elements();
      this.card = elements.create('card',{
        style: {
          base: {
            fontSize: '16px',
            fontWeight: 700
          }
        }
      });
      this.card.mount(this.$refs.card)
      this.card.on('change',(event)=>{
        this.valid = event.complete;
        if ( event.error ) {
          this.message = event.error.message;
          this.mark('cardHolder.card',false)
        } else {
          this.message = ''
          this.mark('cardHolder.card',true)
        }
      })

    } catch( err ) {
      // console.error('PaymentInfo failed to mount',err)
    }

    this.mark('payment','card',false)

  },

  props: {

    user: {
      type: Object,
      required: true
    },

    showErrors: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Array,
      required: true
    }

  },

  methods: {

    mark( field, val ) {
      this.$emit('signup-v2-mark', field, val)
      return val;
    },

    fetchCardToken() {

      const self = this;

      self.message = '';

      return self.stripe.createToken(self.card).then(function(result) {
        if ( result && result.error ) {
          self.message = result.error.message;
          console.error(self.message)
          throw result.error
        } else return result;
      });

    },

    fetchCardholder() {
      return this.cardHolder
    }

  },

  computed: {

    fullName() {
      return this.mark( 'cardHolder.fullName', (this.cardHolder.fullName.trim().length > 0))
    },

    phone() {
      return this.mark( 'cardHolder.phone', (this.cardHolder.phone.trim().length >= 10))
    },

    coupon() {
      return this.mark( 'coupon', true )
    }

  }

}
</script>

<style lang="scss" >

.payment-info {

  .header {
    h3 {
      font-size:28px;
      font-weight:bold
    }
    margin-bottom: 30px;
  }

  label {
    font-size: 16px;
    font-weight: medium;
    margin-bottom: 8px;
  }

  input {
    fot-size: 16px;
    font-weight: bold;
  }

  .form-group .card-entry .form-control {
    font-size: 18px;
    font-weight: bold;
    padding-top: 11px;
  }

  .payment-padded-row {
    font-size: 16px;
    margin-bottom: 20px;
  }

  margin-bottom: 30px;

}

</style>
