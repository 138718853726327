
import { store, actions, getters } from './store'
import Logger from './logger'

// feature definitions
const flags = {
  'feature-ai-v2':{
    id:'feature-ai-v2',
    label:'Generate content using enhanced AI V2',
  },
  'feature-results-v2': {
    id:'feature-results-v2',
    label:'Process generate content using V2 UI',
  }
}

class User {

  static hasFlag(flag,user) {
      user = user || store.user
      user.flags = user.flags || []
      return (user && user.flags && user.flags.includes(flag) )
  }

  static descriptionForFlag(id) {
    const flag = flags[id]
    return flag? flag.label : ''
  }

  static availableFlags(user) {
    user.flags = user.flags || []
    return Object.keys(flags).reduce((acc,key)=>{
      if ( !user.flags.includes(key) ) {
        acc.push(flags[key])
      }
      return acc
    },[])
  }

  static getOnboarding(key) {
    const user = getters.user()
    return user && user.onboarding && user.onboarding[key]
  }

  static async setOnboarding(key,val) {
    const user = getters.user()
    user.onboarding = user.onboarding || {}
    user.onboarding[key] = val
    await actions.patchUser(user._id,'onboarding',user.onboarding)
    if ( key ) Logger.onboardingCompleted(key)
  }

}

export default User




