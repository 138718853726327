<template>

  <div v-if="dashboard && session && product" class="results-view-v2">

    <!-- header/message bar -->
    <ResultsHeader v-if="onboarded && completed" :statusMessage="statusMessage" :session="session" :dashboard="dashboard" :policy="policy" @on-toggle-originals="onToggleAIOriginals"/>

    <!-- posts / editor / actions  when complete or partial results -->
    <div v-if="completed">
      <PostsCarousel ref="postsCarousel" :session="session" :dashboard="dashboard" :policy="policy" @on-status-message="onStatusMessage" />
    </div>

    <!-- otherwise show progress -->
    <div v-if="!completed && !initial">
      <ProgressIndicator :dashboard="dashboard" :session="session" @resume-session="resumeSession" @on-progress-complete="onProgressComplete" />
    </div>

  </div>

</template>

<script>


import ProgressIndicator from './ProgressIndicator'
import ResultsHeader from './ResultsHeader'
import PostsCarousel from './PostsCarousel'

import { actions, getters } from '@/services/store'
import router from '@/services/router'
import User from '@/services/user'

import Vue from 'vue'

// use an irregular poll structure
const pollInterval = () => {
  const min = 1500, max = 5000
  return Math.trunc(Math.random() * (max - min) + min)
}

export default {

  name: 'ResultsView',

  data() {
    return {
      policy:false,
      initial:true,
      session:false,
      completed:false,
      dashboard:false,
      onboarded:false,
      statusMessage:undefined
    }
  },

  props: {
    sessionId: {
      type: String,
      default: ''
    },
  },

  async created() {

    this.onboarded = User.getOnboarding('generator')
    this.policy = await actions.fetchPolicy()
    this.dashboard = getters.dashboard()
    this.product = getters.product()
    this.user = getters.user()

    this.poll();

  },

  methods: {

    onToggleAIOriginals(toggle) {
      console.log('resultsView.onToggleAIOriginals',toggle)
      this.$refs.postsCarousel.onToggleOriginals(toggle)
    },

    onStatusMessage(msg) {
      //console.log('ResultsView.onStatusMessage',msg)
      this.statusMessage = msg
      if ( this.timer ) clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.statusMessage = undefined
      },3000)
    },

    onProgressComplete() {
      this.completed = true
    },

    async resumeSession() {
      const dashboard = getters.dashboard()
      await actions.resumeSession( dashboard._id, this.session._id )
      this.session.status.bucket = 'generating'
      this.poll()
    },

    /**
    * await session completion
    **/
    async poll() {

      const sessionId = this.sessionId || router.currentRoute.params.sessionId
      if ( !sessionId ) return; // possibly navigated

      // fetch the session
      Vue.nextTick(async ()=>{

        this.session = await actions.findSession(sessionId)

        this.user = await actions.fetchUser()
        this.onboarded = User.getOnboarding('generator')
        if ( !this.session ) return this.$router.push(`/${router.currentRoute.params.dashboardId}/sources`);

        // on initial load set completed flag
        if ( this.initial ) {
          this.initial = false
          this.completed = this.session.status.bucket === 'complete' || (this.session.status.bucket === 'generating' && this.session.postsCreated.all)
        }

        if ( this.session.status.bucket === 'open' ) {
          return this.$router.push(`/${this.session.dashboard}/generate/${this.session._id}`)
        } else if ( this.session.status.bucket != 'complete' && this.session.status.bucket != 'failed' ) {
          setTimeout( this.poll, pollInterval() )
        }
      })

    }

  },

  components:{
    ProgressIndicator,
    ResultsHeader,
    PostsCarousel
  }

}
</script>

<style lang="scss" >

.results-view-v2 {


}

</style>
Results
