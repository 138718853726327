<template>

  <div class="d-flex justify-content-between language-picker">
    <span>Transcribe From:</span>
    <b-dropdown id="dropdown-1" :text="language.text" size="sm">
      <b-dropdown-item @click="selectLanguage(language)" v-for="language in languages" :key="language.value">{{language.text}}</b-dropdown-item>
    </b-dropdown>
  </div>

</template>

<script>

  export default {

    data() {
      return {
        language:false,
        languages: [
          { value: 'zh-TW', text: 'Chinese' },
          { value: 'nl-NL', text: 'Dutch' },
          { value: 'en-US', text: 'English' },
          { value: 'fr-FR', text: 'French' },
          { value: 'de-DE', text: 'German' },
          { value: 'pt-PT', text: 'Portuguese' },
          { value: 'es-ES', text: 'Spanish' },
        ]
      }
    },

    created() {
      const selected = this.languages.find((l)=>{return l.value===this.model.language}) || this.languages[1]
      this.selectLanguage(selected)
    },

    props: {
      model: {
        type: Object,
        required:true
      }
    },

    methods: {
      selectLanguage(language) {
        this.language = language
        this.$emit('on-language-selected', this.language.value)
      }
    }

  }

</script>

<style lang="scss">

.language-picker {
  margin-bottom: 0px;
  span {
    font-size: 14px;
    margin-top: 0px;
  }
  button {
    width: 100px;
  }
}

</style>


