import { getters, store } from './store'

// feature definitions
export const features = {
    'send-third-party':{
       id:'send-third-party',
        label:'Send content to third party social media channels',
        units:[],
        limit:-1,
        unit:''
    },
    'send-lately':{
      id:'send-lately',
       label:'Publish content via Lately',
       units:[],
       limit:-1,
       unit:''
   },
    'generate-export':{
       id:'generate-export',
        label:'Export generated posts',
        description:'Export your generated posts for import into your favorite publisher',
        units:[],
        limit:-1,
        unit:''
    },
    'text-generate': {
       id:'text-generate',
        label:'Generate Content from Text',
        description:'Generate content from pasted text, or from a URL to your blog post or whatever!',
        units:[],
        limit:-1,
        unit:''
    },
    'audio-generate': {
       id:'audio-generate',
        label:'Generate from Audio clips',
        units:[],
        limit:-1,
        unit:''
    },
    'video-generate': {
       id:'video-generate',
        label:'Generate Content from Video',
        units:[],
        limit:-1,
        unit:''
    },
    'transcribe-english': {
       id:'transcribe-english',
        label:'Transcribe Videos from English',
        units:[],
        limit:-1
    },
    'transcribe-other': {
       id:'transcribe-other',
        label:'Transcribe Videos from other languages',
        units:[],
        limit:-1,
        unit:''
    },
    'transcript-edit': {
      id:'transcript-edit',
       label:'Edit Audio and Video transcripts prior to generation',
       units:[],
       limit:-1,
       unit:''
   },
    'video-clip':{
       id:'video-clip',
        label:'Attach video clips',
        units:[],
        limit:-1,
        unit:''
    },
    'video-intro-outro':{
       id:'video-intro-outro',
        label:'Generate and attach intro/outro videos to video clips',
        units:[],
        limit:-1,
        unit:''
    },
    'video-caption-add':{
       id:'video-caption-add',
        label:'Add captions to video clips',
        units:[],
        limit:-1,
        unit:''
    },
    'video-caption-edit':{
       id:'video-caption-edit',
        label:'Edit captions added to video clips',
        units:[],
        limit:-1,
        unit:''
    },
    'connect-third-party':{
       id:'connect-third-party',
      label:'Connect third party social media channels',
      units:[{
        key:'total',
        val:'Total Channels'
      },{
        key:'type',
        val:'Channels per Type'
      }],
      limit:1,
      unit:'total'
    },
    'connect-lately':{
      id:'connect-lately',
      label:'Connect social media channels via Lately',
      units:[{
        key:'total',
        val:'Total Channels'
      },{
        key:'type',
        val:'Channels per Type'
      }],
      limit:1,
      unit:'total'
   },
    'invite-team-members':{
      id:'invite-team-members',
       label:'Invite team members to your dashboard',
       units:[],
       limit:-1,
       unit:''
   }
}

// returns true when product includes feature
// does not yet incorporate limits
export const includes = (ids,product)=>{

    ids = ids.split(',')

    product = product || store.product

    const matches = ids.reduce((acc,id)=>{
      const feature = product && product.limits? product.limits.find((f)=>{return f.id===id}) : undefined
      if ( feature!=undefined ) {
        acc.push(id)
      }
      return acc
    },[])

    return (matches.length > 0)
}

export const buildAllLimits = ()=>{

  return Object.keys(features).reduce((acc,key)=>{
    const feature = features[key]
    acc.push(Object.assign({},feature))
    return acc
  },[])

}

/**
 * Returns a genre specific result describing remaining limits
 **/
export const remainingLimits = (genre,source)=>{

  // console.log('remainingLimits', genre,source)

  const dashboard = getters.dashboard()
  if ( !dashboard ) throw new Error('dashboard not found')

  const product = getters.product()
  if ( !product ) throw new Error('product not found')

  const partner = getters.partner()

  switch (genre) {

    case 'channels': {

      // find a limit definition matching the current partner. plan should have both!
      const limitId = partner === 'lately'? 'connect-lately' : 'connect-third-party'
      let limit = product.limits.find((l)=>{ return l.id === limitId })
      if ( !limit || limit.limit === -1 || !limit.unit ) {
        // console.error(`No channel limit or uninitialized channel limit for ${product.name}:${product.id} for partner ${partner}`)
        // setup defaults. not great but how else to deal with this?
        switch ( product.name ) {
          case 'Startly': {
            limit = { all: 1 }
            break
          }
          case 'Litely': {
            limit = { facebook: 1, twitter: 1, instagram: 1, linkedin: 1}
            break;
          }
          case 'Professionally': {
            limit = { facebook: 5, twitter: 5, instagram: 5, linkedin: 5}
            break;
          }
          default: {
            limit = { all:0 }
            break;
          }
        }
      }

      // setup bounds based on limit
      if ( limit.unit === 'total' ) {
        limit = { all: limit.limit }
      } else if ( limit.unit === 'type' ) {
        limit = {
          facebook: limit.limit,
          twitter: limit.limit,
          instagram: limit.limit,
          linkedin: limit.limit
        }
      }

      // compute current counts from dashboard
      const actual = dashboard.channels.reduce((acc,ch)=>{
        if ( !ch.needsSelection ) {
          acc[ch.type] += 1
          acc.all += 1
        }; return acc
      },{all:0,facebook:0,twitter:0,instagram:0,linkedin:0})

      // adjust limits based on actual
      if ( limit.all ) {
        limit.all = limit.all-actual.all
      } else {
        limit.instagram = limit.instagram-actual.instagram
        limit.facebook = limit.facebook-actual.facebook
        limit.linkedin =limit.linkedin-actual.linkedin
        limit.twitter = limit.twitter-actual.twitter
      }

      return limit;

    }

  }

}




