
<template>

  <b-container class="vh-100">

    <b-row class="h-75" align-v="center" >

      <div class="col-md-6 offset-md-3">

        <h1>Welcome back to Lately!</h1>
        <p class="lead">Hi there, superstar. We missed ya.</p>
        <p>Your subscription appears to be inactive! If you'd like to continue using Lately please head on over to our <a href="#" @click="portal()">Payment Portal</a> to update your
        payment method or reactive your subscription, or  <a target="blank" href="mailto:customerlove@lately.ai?subject=IncreaseChannels">Contact Us</a> if you have questions.</p>

      </div>

    </b-row>

  </b-container>

</template>

<script>

  'use strict'

  import { actions } from "@/services/store.js";

  export default {

    name: 'Subscription',

    methods:{

      async portal() {
        const resp = await actions.fetchStripePortalUrl()
        return document.location.href = resp.data.url
      }

    }

  }
</script>

<style>

.signup-link {
  font-size:22px!important;
}

.signup-link a {
  margin-left:5px;
}

</style>
