<template>

  <div class="onboard-page onboard-workflow">

    <div class="row onboard-page-header">
      <div class="col-md-10 offset-1">
          <img src="@/assets/onboarding/onb_getready.png" height="199px"/>
      </div>
      <div class="col-md-10 offset-1">
        <span class="title">We got this! Welcome to your new AI social workflow: </span>
      </div>
    </div>

    <div class="container onboard-page-body">
      <div class="row lifting-body">
        <div class="col-8 offset-2 steps-text">
          <div class="steps-intro-list-container">
            <div class="row step-entry" v-for="(step,idx) in steps" :idx="idx">
              <div class="col-md-2">
                <span class="step-bullet">Step {{idx+1}}</span>
              </div>
              <div class="col-md-10">
                <span class="step-text">{{step}}</span>
              </div>
            </div>
          </div>
        </div>

        <b-col cols="10" offset="1">
          <div class="d-flex justify-content-center pro-tip">
            <img src="@/assets/onboarding/fire.png" />
            <span class="pro-tip-description text-center">
              <i><b>Pro Tip!</b> Lately users that follow these 4 steps see an average increase of 240% engagement and save 84% time spent writing. </i>
            </span>
            <img src="@/assets/onboarding/fire.png" />
          </div>
        </b-col>
      </div>
    </div>

    <div class="row onboard-page-footer">
      <div class="col-md-10 offset-1">
        <b-button @click="next(-1)" class="onboard-back">BACK</b-button>
        <b-button @click="next(1)" class="onboard-continue">LET'S GOOOO!</b-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import ChannelIcon from '@/components/ChannelIcon'
import { media } from '@/services/constants'

export default {

  name: 'lifting',

  data() {
    return {
      steps:[
        `Ingest 2+ pieces of longform content per week. `,
        `Train the AI with a few quicky edits.`,
        `Auto-publish your fave results.`,
        `Brag about your new AI bff.`
      ]
    }
  },

  methods: {

    next(dir) {
      this.$emit('on-next',dir)
    },

    colorFor(ch) {
      return media[ch].color
    },

    backgroundFor(ch) {
      return media[ch].color
    },

    borderFor(ch) {
      if ( this.channel === ch ) {
        return media[ch].color
      } else return '#D6D6D6'
    },

    iconFor(ch) {
      switch(ch) {
        case 'facebook': return 'facebook-f';
        case 'linkedin': return 'linkedin-in'
        default: return ch
      }
    }

  },

  components: {
    ChannelIcon
  }

}
</script>

<style lang="scss" >

 .onboard-workflow {

  .lifting-header {
    padding-bottom: 10px;
    span {
      font-size: 42px;
      font-weight: bold;
      color: #2D2D2D;
      padding: 24px 0px 24px 0px!important;
    }
  }

  .ready-text {
    background-color:white;
    font-size: 28px;
    padding: 54px 48px 36px 48px;
  }

  .steps-intro-list-container {

    background-color: #F7F7F7;
    padding: 36px;
    border-radius: 10px;
    padding: 36px;

    .step-entry {
      margin-bottom: 10px;
    }

    .step-bullet {
      color: #20C763;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px!important;
    }

    .step-text {
      font-size: 24px;
    }

  }

  .pro-tip {

    font-size: 24px;
    background-color: #EEFBF4!important;
    border-radius: 20px;
    margin-top: 36px;
    padding: 24px;
    font-style: italic;

    img {
      height:52px;
      margin-top:10px;
    }

    .pro-tip-description {
      font-size: 24px;
      padding: 0px 15px 0px 15px;
    }
  }
}

</style>
