<template>

  <div class="row">

    <div class="col-md-12">
      <h3>Let's connect Lately to your social media publisher.</h3>
      <p>
        Choose your preferred social media platform below.
        Once connected you'll be able to instantly send all of your AI-generated content directly to them for publishing.
        <I>Friendly reminder, Lately only offers on-premise publishing in the Enterprisely plan.</i>
      </p>
    </div>

    <div class="col-md-3" v-for="publisher in publishers" :key="publisher.id">

      <div class="publisher">

        <div class="publisher-logo text-center">
          <img :src="logoFor(publisher)" width="100%">
        </div>
        <br>
        <p>{{publisher.description}}</p>

        <div class="text-center">

          <b-button @click="select(publisher)" block class="publisher-connect" variant="success" >
            SELECT
          </b-button>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import { actions, getters } from '@/services/store'
import { Publishers } from '@/services/constants'

export default {

  name: 'ConnectPublisher',

  data() {
    return {
      selections: {},
      needsSelection: {},
      publishers: Publishers.filter(p=>p.enabled)
    }
  },

  created() {

    this.dashboard = getters.dashboard()

    // initialize needsSelection from dashboard
    this.dashboard.channels.reduce((acc,ch)=>{
      if ( ch.needsSelection ) {
        const bucket = acc[ch.type] = acc[ch.type] || []
        bucket.push(ch)
      }
      return acc;
    }, this.needsSelection )

    // initialize selections
    Object.keys(this.needsSelection).forEach((type)=>{
      this.needsSelection[type].forEach((ch)=>{
        this.selections[ch._id] = false;
      })
    })

  },

  methods: {

    logoFor(publisher) {
      return require(`@/assets/integrations/${publisher.img}`)
    },

    select(publisher) {
      this.$emit('select-publisher', publisher.id )
    },

    async portal() {
      const p = await actions.fetchStripePortalUrl()
      document.location.href = p.data.url
    },

  }

}
</script>

<style lang="scss" >

.publisher {
  border: 1px solid grey;
  border-radius:10px;
  min-height:300px;
  padding: 15px;
  background-color: #fff;
}

.publisher-logo img {

}

.publisher-connect {
  position: absolute;
  max-width: 80%;
  bottom: 10px;
}

</style>
