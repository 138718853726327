<template>

    <div class="row" v-if="user">

        <div class="col-md-12">
            <h4>My Account</h4>
            <hr>
        </div>

        <div class="form-group col-md-6">
            <label>First Name</label>
            <b-form-input v-model="user.firstname" class="form-control" :state="firstname"></b-form-input>
            <b-form-invalid-feedback :state="firstname">
                Please enter your first name.
            </b-form-invalid-feedback>
        </div>

        <div class="form-group col-md-6">
            <label>Last Name</label>
            <b-form-input v-model="user.lastname" class="form-control"  :state="lastname" ></b-form-input>
            <b-form-invalid-feedback :state="lastname">
                Please enter your last name.
            </b-form-invalid-feedback>
        </div>

        <div class="form-group col-md-6">
            <label>Email</label>
            <b-form-input v-model="user.email" type="email" class="form-control div-md-2"  :state="email" ></b-form-input>
            <b-form-invalid-feedback :state="email">
                Please enter your email.
            </b-form-invalid-feedback>
        </div>

        <div class="form-group col-md-6">
            <label>Phone Number</label>
            <b-form-input v-model="user.phone" type="tel" class="form-control div-md-2"  :state="phone" ></b-form-input>
            <b-form-invalid-feedback :state="phone">
                Please enter your Phone number.
            </b-form-invalid-feedback>
        </div>

        <div class="form-group col-md-12">
            <label>Interests</label>
            <Interests ref="userInterests" :initial="user.interests" />
        </div>

        <div class="form-group col-md-12">
            <span class="float-right">
                <b-button @click="reset()" variant="default">Reset</b-button>
                <b-button @click="submit()" :disabled="(errors.length>0)" variant="primary">Save</b-button>
            </span>
        </div>

    </div>

</template>

<script>

import { actions, store } from '@/services/store';
import Interests from '@/components/Interests'

export default {

  name: 'PersonalInfo',

  data() {
        return {
          user:false,
          errors:[],
        }
    },

    async created() {
      const user = await actions.fetchUser();
      user.interests = user.interests || []
      this.user = JSON.parse(JSON.stringify(user)); // for reset
    },

    methods: {

      mark(key,val) {
        const idx = this.errors.indexOf(key)
        if ( idx === -1 && !val ) this.errors.push(key)
        else if ( idx != -1 && val ) this.errors.splice(idx,1)
        return val;
      },

      reset() {
        this.user = JSON.parse(JSON.stringify(store.user))
      },

      async submit() {

        try {

          const selections = this.$refs.userInterests.fetchSelections()

          await actions.updateUser({
            _id:this.user._id,
            firstname:this.user.firstname,
            lastname:this.user.lastname,
            email:this.user.email,
            website:this.user.website,
            phone:this.user.phone,
            interests: selections
          })

          this.$toasted.success(`Account info updated`)

        } catch( err ) {
          console.error(err)
          this.$toasted.error(`Failed to update your personal info`)
        }

      }

    },

    computed: {

        firstname() {
            const val = new Boolean(this.user.firstname && this.user.firstname.length>0).valueOf()
            return this.mark('firstname',val)
        },

        lastname() {
            const val = new Boolean(this.user.lastname && this.user.lastname.length>0).valueOf()
            return this.mark('lastname',val)
        },

        email() {
            var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            return this.mark( 'email',(re.test(this.user.email)))
        },

        phone() {
            const val = new Boolean(this.user.phone && this.user.phone.length>=10).valueOf()
            return this.mark('phone',val)
        },

        website() {
            const matches = typeof this.user.website == 'string'? this.user.website.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm) || [] : []
            return  this.mark( 'website',(matches.length>0))
            //const val = new Boolean(this.user.website && this.user.website.length>0).valueOf()
            //return this.mark('website',val)
        }
    },

    components: {
      Interests
    }

}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
