<template>
  <div class="content-filter-channels">
    <b-dropdown id="channel-dropdown" :text="!selections.length? 'Select channels' : selections.length + ' Channels'" class="m-2" @click="noop($event)">
      <b-dropdown-group :header="key|channelname|titlecase" v-for="(group,key) in groups" :key="key" @click="noop($event)">
        <a class="dropdown-item" v-for="channel in group" :key="channel.channel" @click="select($event,channel)">
          <i class="fas fa-fw" :class="checked(channel)? 'fa-check':''" />
          {{channel.name}}
        </a>
      </b-dropdown-group>
    </b-dropdown>
  </div>
</template>

<script>

import { getters } from '@/services/store'
import Session from '@/services/session'

const SESSION_KEY = 'list-channel-filter'

export default {
  name: 'ChannelPicker',
  data() {
    return {
      all: {
        checked:false
      },
      scope:'',
      groups:{},
      channels: [],
      selections:[]
    }
  },
  props: {
    dashboard: {
      type: Object,
      required:true
    },
    config: {
      type: Object,
      required:true
    }
  },

  methods: {

    noop(ev){
      ev.stopPropagation()
      ev.preventDefault()
      return false
    },

    checked(ch) {
      return this.selections.filter((ch2)=>{return ch2.channel===ch.channel}).length>0
    },

    select(ev,channel) {

      let idx = this.selections.findIndex((ch)=>{return ch.channel===channel.channel})
      if ( idx === -1 ) {
        this.selections.push(channel)
      } else {
        this.selections.splice(idx,1)
      }

      Session.set(SESSION_KEY,this.selections)

      this.$emit('on-filter',{
        key:'channels',
        val:this.selections
      })

      return false
    }
  },

  created() {

    // identify selectable channels
    this.channels = this.dashboard.channels.filter((ch)=>{
      return this.config.value === '*' || this.config.value.includes(ch.type)
    }).map((ch)=>{
      return {
        type:ch.type,
        channel:ch.content.id,
        name:ch.content.name,
        icon:ch.content.icon
      }
    })

    // restore prior selections
    const selections = Session.get(SESSION_KEY)
    if ( !selections ) {
      this.selections = this.channels.slice()
      Session.set(SESSION_KEY, this.selections)
    } else {
      this.selections = this.channels.filter((ch)=>{
        return selections.find((ch2)=>{return ch.channel === ch2.channel})
      }).slice()
    }

    // group by type
    this.groups = this.channels.reduce((acc,ch)=>{
      const bucket = acc[ch.type] = acc[ch.type] || []
      bucket.push(ch)
      return acc
    },{})

    // inform the parent
    this.$emit('on-filter',{
      key:'channels',
      val:this.selections
    })

  }

}
</script>

<style lang="scss" >

.content-filter-channels {
  display: inline;
  #channel-dropdown {
    margin: 0px!important;
  }
  .dropdown-toggle {
    width: 200px;
    height: 38px;
    padding: 5px 0px 5px 10px;
    display: inline;
    text-align: left;
    background-color: lightgrey;
    border-color: lightgrey;
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
}

</style>
