<template>

  <div class="row content-attachments-v2" v-if="post.attachments.length">

    <!-- non-video sessions -->
    <div class="col-md-3" v-if="session && session.config.source!=='video'" v-for="attachment in post.attachments" :key="attachment._id">
      <Attachment :post="post" :attachment="attachment" :policy="policy" :errors="errors" @detached="detached" @attach-error="attachError" />
    </div>

    <!-- video sessions -->
    <div class="col-md-12" v-if="session && session.config.source==='video' && post.attachments.length">
      <VideoEditor  :source="post.attachments[0]" :post="post" @detached="detached" @captions-edited="captionsEdited" />
    </div>

  </div>

</template>

<script>

import { media } from '@/services/constants'
import VideoEditor from './VideoEditor'
import Attachment from './Attachment'

export default {

  name: 'Attachments',

  data() {
    return {
      type:''
    }
  },

  props: {
    post: {
      type: Object,
      required: true
    },
    policy: {
      type: Object,
      required: true
    },
    session: {
      type: Object
    },
    errors: {
      type: Array,
      required: true
    }
  },

  created() {
    this.type = this.post.contentType === 'temporary'? this.post.channel : this.post.contentType
    this.assessAttachments()
  },

  methods: {

    // propagate to contentpost
    detached(attachment) {
      this.$emit('detached', attachment)
    },

    // propagate to contentpost
    attachError(error) {
      this.$emit('attach-error', error)
    },

    pushError(error) {
      this.errors.push(error)
    },

    captionsEdited(captions) {
      this.$emit('captions-edited',captions)
    },

    assessAttachments() {

      // remove attachment oriented errors
      const errs = this.errors.filter((e)=>{return e.type==='attachment'})
      errs.forEach((e)=>{
        const idx = this.errors.indexOf(e)
        this.errors.splice(idx,1)
      })

      const props = media[this.type]
      const attach = this.post.attachments

      const images = attach.filter((a)=>{return a.mimetype && a.mimetype.startsWith('image')})
      const audios = attach.filter((a)=>{return a.mimetype && a.mimetype.startsWith('audio')})
      const videos = attach.filter((a)=>{return a.mimetype && a.mimetype.startsWith('video')})

      if ( images.length && audios.length )  {
        this.pushError({type:'attachment', message: `${props.label} does not support image and audio attachments in the same post`})
      }
      if ( images.length && videos.length )  {
        this.pushError({type:'attachment', message: `${props.label} does not support image and video attachments in the same post`})
      }
      if ( audios.length && videos.length )  {
        this.pushError({type:'attachment', message: `${props.label} does not support audio and video attachments in the same post`})
      }

      if ( !props.minImages && !props.minVideos && attach.length < props.minAttachments ) {
        this.pushError({type:'attachment', message: `${props.label} requires at least ${props.minAttachments} attachments`})
      }

      if ( attach.length > props.maxAttachments ) {
        this.pushError({type:'attachment', message: `${props.label} permits at most ${props.maxAttachments} attachments`})
      }
      if ( images.length < props.minImages ) {
        this.pushError({type:'attachment', message: `${props.label} requires at least ${props.minImages} image attachments`})
      }
      if ( images.length > props.maxImages ) {
        this.pushError({type:'attachment', message: `${props.label} permits at most ${props.maxImages} image attachments`})
      }
      if ( videos.length < props.minVideos ) {
        this.pushError({type:'attachment', message: `${props.label} requires at least ${props.minVideos} video attachments`})
      }
      if ( videos.length > props.maxVideos ) {
        this.pushError({type:'attachment', message: `${props.label} permits at most ${props.maxVideos} video attachments`})
      }
    }

  },

  watch: {

  'post.attachments': {

      handler () {
        this.assessAttachments()
      },

      deep: true
    }

  },

  components: {
    VideoEditor,
    Attachment
  }

}
</script>

<style lang="scss" >

.content-attachments-v2 {
  margin-top: 20px;
  padding: 18px 14px 30px 14px;
  background-color: #F4F4F4
}

</style>
