<template>

  <b-container class="signup-page account-info">

    <div class="row">

      <!-- product info -->
      <div class="col-md-7 offset-1">
        <div class="product-info">

          <span class="context">GET STARTED</span>
          <h3 class="title">Lately 7-Day Free Trial</h3>
          <p class="description">Try Lately for <i>free</i> for 7-days. Cancel anytime.</p>
          <h4 class="banner">Spin your hard work into social gold!</h4>
          <ul class="list-unstyled">
            <li>
              <div class="d-flex">
                <i class="fas fa-check" />
                <span>Run any longform content thru Lately's AI.</span>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <i class="fas fa-check" />
                <span>Works with any longform text, video, podcasts.</span>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <i class="fas fa-check" />
                <span>From text, we'll generate dozens of social posts.</span>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <i class="fas fa-check" />
                <span>From audio, we'll generate a transcript, and dozens of social posts <i>with matching audio clips.</i></span>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <i class="fas fa-check" />
                <span>From video, we'll generate a transcript, and dozens of social posts <i>with matching video clips.</i></span>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <i class="fas fa-check" />
                <span>Schedule out a drip feed of your faves.</span>
              </div>
            </li>
            <li>
              <div class="d-flex">
                <i class="fas fa-check" />
                <span>Rinse and repeat.</span>
              </div>
            </li>

          </ul>

        </div>
      </div>

      <!-- account form -->
      <div class="col-md-4 account-form">

        <h3 class="heading">Sign up for Lately</h3>

        <div class="back">
          Already have Lately account? <a href="#/app/Login">Log In</a>
        </div>

        <b-form @submit.prevent="login" action="#">

          <div class="row">

            <!-- first name -->
            <b-form-group class="col-md-12" placeholder="">
              <label>First Name</label>
              <b-form-input
                autofocus
                v-model="user.firstName"
                type="text"
                placeholder=""
                :state="vfirstName"
              ></b-form-input>
            </b-form-group>

            <!-- last name -->
            <b-form-group class="col-md-12" placeholder="">
              <label>Last Name</label>
              <b-form-input
                v-model="user.lastName"
                type="text"
                placeholder=""
                :state="vlastName"
              ></b-form-input>
            </b-form-group>

            <!-- email -->
            <b-form-group class="col-md-12" placeholder="">
              <label>Email Address</label>
              <b-form-input
                v-model="user.email"
                type="email"
                placeholder=""
                :state="vemail"
              ></b-form-input>
            </b-form-group>

            <!-- password -->
            <b-form-group class="col-md-12" placeholder="">
              <label>Password (must be at least 8 characters)</label>
              <b-form-input
                autocomplete="password"
                v-model="user.psswd"
                type="password"
                placeholder=""
                :state="vpassword"
              ></b-form-input>
            </b-form-group>

            <!-- password confirmation -->
            <b-form-group class="col-md-12" placeholder="">
              <label>Confirm Password</label>
              <b-form-input
                autocomplete="confirm-password"
                v-model="user.psswdconf"
                type="password"
                placeholder=""
                :state="vpasswordConf"
              ></b-form-input>
            </b-form-group>

            <!-- TODO - externalize key -->
            <div class="col-md-12">
            <vue-recaptcha class="recaptcha" @verify="verify" sitekey="6LcusOMUAAAAAPGsILVntrzQ0Tg2hNwYT_8iMEgm" :loadRecaptchaScript="true"></vue-recaptcha>
            </div>

            <div v-if="message" class="message">
              <p class="text-danger" v-if="message==='exists-ss'">Please use another email address.</p>
              <p class="text-danger" v-else-if="message==='exists-lately'">That email address is associated with a Lately Enterprisely account.
              Please use another email address, or <a :href="'https://dashboard.lately.ai/login?email='+user.email">Sign In to my Enterprisely Account</a></p>
              <p class="text-danger" v-else>{{message}}</p>
            </div>

            <div class="actions">
              <b-button block :disabled="errors.length>0" variant="success" @click="submit()">NEXT</b-button>
            </div>

          </div>

        </b-form>

      </div>


    </div>

  </b-container>

</template>

<script>

  'use strict'

  import VueRecaptcha from 'vue-recaptcha';

  export default {

    name: 'Login',

    data() {
      return {
        user: {
          email:'',
          psswd:'',
          psswdconf:'',
          website:'',
          recaptcha:'',
          firstName:'',
          lastName:'',
          recaptcha: '',
        },
        errors: ['recaptcha']
      }
    },

    created() {
      console.log('AccountInfo.created')
    },

    props: {
      message: {
        type: String,
        default: ''
      }
    },

    methods: {
      verify(token) {
        this.user.recaptcha = token
        this.mark('recaptcha',true)
      },
      mark( field, val ) {
        const idx = this.errors.indexOf(field)
        if ( idx==-1 && !val ) this.errors.push(field)
        else if ( val && idx >= 0 ) this.errors.splice(idx,1)
        return val;
      },
      submit() {
        this.$emit('account-info-selected', this.user)
      }
    },

    computed: {
      vfirstName() {
        return  this.mark('firstName',(typeof this.user.firstName == 'string' && this.user.firstName.length > 0 ))
      },
      vlastName() {
        return  this.mark('lastName',(typeof this.user.lastName == 'string' && this.user.lastName.length > 0 ))
      },
      vemail() {
        var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var constraints = {
          from: {
            email: true
          }
        };
        return this.mark('email',(re.test(this.user.email)))
      },
      vpassword() {
        return  this.mark('psswd',(typeof this.user.psswd == 'string' && this.user.psswd.length >= 8))
      },
      vpasswordConf() {
        return  this.mark('psswdconf',(typeof this.user.psswd == 'string' && this.user.psswd.length >= 8 && this.user.psswdconf === this.user.psswd ))
      },
    },

    components: {
      VueRecaptcha
    }

  }
</script>

<style lang="scss" >

.account-info {

  font-face: Noto Sans;

  padding: 10px;

  .product-info {

    .context {

    }

    .title {
      margin-top: 20px;
      font-size: 26px;
      font-weight: bold;
      color: #2D2D2D
    }

    .description {
      margin-top: 10px;
      font-size: 22px!important;
      font-weight: bold;
      color: #2D2D2D
    }

    .banner {
      margin-top: 30px;
      color: #20C763;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    ul {
      padding: 0px 24px 0px 24px;
      margin-bottom: 96px;
    }
    ul li {
      margin-bottom: 12px;
      font-size:20px;
    }
    ul li .fas {
      margin-top: 8px;
      font-size: 12px;
      color: #20C763;
      margin-right: 12px;
    }

  }

  .account-form {

    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 0px 0px 5px 5px;
    border-top: 5px solid #20C763;
    box-shadow: 2px 2px 2px lightgrey;

    .form-group {
      margin: 0px;
    }

    label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    input {
      margin-bottom: 30px;
    }

    .recaptcha {
      transform:scale(1.15);
      -webkit-transform:scale(1.16);
      transform-origin:0 0;
      -webkit-transform-origin:0 0;
      margin: 0px;
    }

    .message {
      margin: 20px 0px 10px 3px;
      a {
        color: #008CFF
      }
    }

    .actions {
      margin-top: 40px;
      button {
        font-size: 18px;
        font-weight: bold;
        color: white;
        border: 1px solid #20C763;
        border-radius: 8px;
        padding: 10px 30px 10px 30px;
        background-color: #20C763;
        margin-bottom: 30px;
      }
    }

    .heading {
      font-weight: bold;
      font-size: 28px;
      margin-top: 20px;
    }

    .back {
      margin-bottom: 40px;
      font-size: 15px;
      font-weight: regular;
      color: #2D2D2D;
      a {
        color: #008CFF
      }
    }
  }

}

</style>
