<template>

  <div class="generate-from-text" v-if="model">

    <b-overlay :show="generating">

      <!-- selector -->
      <div key="selector" class="row text-center select" v-if="state==='select'">

        <div class="col-md-12">
          <h3>Generate Social Posts from Longform Text.</h3>
          <p>Choose one of the longform text options below that you'd like to generate social media posts from.</p>
        </div>

        <div class="col-md-3 source-type text-center" @click="setState('url')">
          <div class="content">
            <img src="@/assets/generator/url.svg" />
            <h4>URL</h4>
            <p>Paste the URL of a blog post or news article.</p>
          </div>
        </div>

        <div class="col-md-3 source-type text-center"  @click="setState('paste')">
          <div class="content">        
            <img src="@/assets/generator/paste.svg" />
            <h4>Copy/Paste</h4>
            <p>Paste the full text of any document.</p>
          </div>
        </div>

        <div class="col-md-3 source-type text-center"  @click="setState('prompt')">
          <div class="content">        
            <img src="@/assets/generator/prompt.svg" />
            <h4>Prompt</h4>
            <p>Type a prompt and we'll create longform text for you.</p>
          </div>
        </div>
        
        <div class="col-md-3 source-type text-center"  @click="setState('search')">
          <div class="content">        
            <img src="@/assets/generator/search.svg" />
            <h4>Search</h4>
            <p>Find a blog that matches your interests.</p>
          </div>
        </div>      

      </div>

      <!-- url input -->
      <div key="url" class="row url" v-if="state==='url'">
        <div class="col">
          <div class="section" >
            <p class="heading">Paste the URL of a blog post or news article.

            </p>
            <div class="d-flex flex-row">
              <b-form-input class="flex-grow-1" v-model="model.text" placeholder="https://www.mysite.com/blog/blog-post.html" />
            </div>     
            <p class="actions">
              <span class="float-right">
                <b-button @click="state='select'" variant="link">Back</b-button>  
                <b-button @click="generate()" :disabled="!model.valid" class="generate" variant="primary">GENERATE</b-button>
              </span>
            </p>                   
          </div>
        </div>
      </div>

      <!-- pasted text -->
      <div key = "paste" class="row paste" v-if="state==='paste'">
        <div class="col">
          <div class="d-flex flex-row section" @click="model.type='text'">
            <div class="content">
                <p class="heading">Paste the full text of any document. 
                
                </p>
                <div class="body">
                  <div class="editor">
                    <b-form-textarea
                      style="border:none"
                      id="textarea"
                      v-model="model.text"
                      @paste="onPaste"
                      placeholder="Paste 1,000 characters or more. Can be anything from a book chapter, entire blog post, etc."
                      rows="8"
                    ></b-form-textarea>
                    <small class="float-right counter">{{words}} Words, {{ model.text.length }} Characters</small>
                  </div>
                  <div class="link">
                    <p>If you'd like, you can add a link that will appear in each of your results. </p>
                    <b-form-input v-model="model.link" placeholder="https://www.mysite.com/blog/blog-post.html" />
                  </div>
                </div>
                <p class="actions text-right">
                  <span class="float-left text-danger minimum-length-warning" v-if="pasted && model.text.length < 1000">Please provide at least 1000 characters.</span>
                  <span class="float-right">
                    <b-button @click="state='select'" variant="link">Back</b-button>                    
                    <b-button @click="generate()" :disabled="!model.valid" class="generate" variant="primary">GENERATE</b-button>               
                  </span>
                </p>                
            </div>
          </div>
        </div>
      </div>

      <!-- prompt generated -->
      <div key="prompt" class="row prompt" v-if="state==='prompt'">
        <div class="col">
          <div class="d-flex flex-row section" @click="model.type='text'">
            <div class="content">
              <b-overlay :show="composing">                    
                <p class="heading">Type a prompt and we'll create longform text for you.                </p>
                <div class="body">
                  <div class="editor">
                    <b-form-textarea
                      style="border:none"
                      id="textarea"
                      v-model="model.text"
                      placeholder="Give us a prompt, like 'explain the importance of customer service' or 'write me a blog on generative AI'"
                      rows="8"
                    ></b-form-textarea>
                    <span class="float-right">
                        <small class="counter">{{words}} Words, {{ model.text.length }} Characters</small>
                        <b-button size="sm" @click="compose($event)" class="compose" variant="primary">COMPOSE</b-button>
                      </span>
                  </div>
                  <div class="link">
                    <p>If you'd like, you can add a link that will appear in each of your results. </p>
                    <b-form-input v-model="model.link" placeholder="https://www.mysite.com/blog/blog-post.html" />
                  </div>
                </div>
                <p class="actions text-right">
                  <span class="float-left text-danger minimum-length-warning" v-if="composed && model.text.length < 1000">Please provide at least 1000 characters.</span>
                  <span class="float-right">
                    <b-button @click="state='select'" variant="link">Back</b-button>                    
                    <b-button @click="generate()" :disabled="!model.valid" class="generate" variant="primary">GENERATE</b-button>               
                  </span>
                </p>                     
              </b-overlay>
            </div>
          </div>
        </div>
      </div>    

      <!-- search-->
      <div key="search" class="row search" v-if="state==='search'">
        <div class="col">
          <b-form @submit="fetchBlogs($event)">
            <div class="section" >
              <p class="heading text-left">Find a blog that matches your interests. </p>
              <div class="d-flex flex-row input">
                <b-input-group>            
                  <b-form-input 
                    ref="search"
                    type="search" 
                    debounce="500" 
                    class="flex-grow-1" 
                    v-model="model.search" 
                    placeholder="Enter a search term, for example financial planning, fashion, healthcare" />
                    <b-input-group-append>
                    <b-input-group-text><i @click="clearSearch()" class="fa fa-ban" /></b-input-group-text>
                  </b-input-group-append>                          
              </b-input-group>
              </div>
              <p class="actions text-right">
                  <b-button @click="state='select'" variant="link">Back</b-button>            
                  <b-button type="submit" class="generate" @click="fetchBlogs($event)" variant="primary">Search</b-button>                    
              </p>     
            </div>
          </b-form>
        </div>
      </div>  

      <div key="search-results" class="row search-results" v-if="state==='search'">
        <div class="col no-results" v-if="searchMsg">
          <p>{{ searchMsg }}</p>
        </div>
        <div class="col-md-4" v-for="blog in blogs" :key="blog.article.href">
          <div class="blog-entry">
            <img :ref="blog.article.id" :src="blog.article.image_url" onerror="this.src='/not-found.webp'" fluid />
            <div class="content flex-grow-1">
              <p>
                <span class="creator">{{ blog.article.creator }}</span>
                <a class="link float-right" :href="blog.article.short_url" target="new"><i class="fa fa-link"/></a>
              </p>
              <h4>{{blog.article.title}}</h4>
              <p class="text-center"><b-button @click="selectBlog(blog)" class="generate" variant="primary">GENERATE</b-button></p>
            </div>
          </div>
        </div>
      </div>

    </b-overlay>

  </div>

</template>

<script>

'use strict'

import { actions, getters } from '@/services/store.js'

import GPT from '@/components/GPT'
import Vue from 'vue'

import * as linkify from 'linkifyjs'

export default {

  name: 'GenerateFromText',

  data() {
    return {
      dashboard: getters.dashboard(),
      generating: false,
      searching: false,
      composing: false,
      searchMsg: '',
      pasted: false,      
      composed: false,
      model: {
        valid: false,
        search: '',
        type:'text',
        text:'',
        url: '',
        link: ''
      },
      state: 'select',
      user: false,
      busy: false,
      blogs:[]
    }
  },

  props: {
    session: {
      type: Object,
      required: true
    }
  },

  created() {
    this.user = getters.user()
    console.log(JSON.stringify(this.session,0,1))
    if ( this.session.status.bucket !== 'open') {
      const source = this.session.config.steps.source
      const links = this.session.config.steps.links || { links: { all:'' } }
      this.model.type = source.type
      this.model.text = source.type === 'url'? source.url : source.text
      this.model.link = links.links.all
      this.state = source.type === 'url'? 'url' : 'paste'
    }
  },

  computed: {
    words() {
      const text = this.model.text.trim()
      return text.length? text.split(' ').length : 0
    }
  },


  methods: {

    onPaste() {
      this.pasted = true
    },

    generate() {
      this.$emit('on-generate')
    },

    onError(blog) {
      console.log('onImageError', blog)
    },

    setState(state) {
      this.state = state
      switch( state ) {
        case 'url': return this.model.type = 'url'
        case 'text': return this.model.type = 'text'
        case 'prompt': return this.model.type = 'text'
        case 'search': return this.model.type = 'url'                        
      }
    },

    async compose(ev) {
      ev.stopPropagation()
      ev.preventDefault()
      this.composing = true
      try {
        const dashboard = getters.dashboard()
        const response = await actions.gptRequest( dashboard._id, 'text', this.model.text )
        Vue.nextTick(()=>{
          this.model.text = response.content.trim()
        })
        this.composed = true
      } catch ( err ) {
        console.error(err)
      } finally {
        this.composing = false

      }
    },

    onGptAccept(text) {
      this.model.type = 'text'
      this.model.text = text
    },

    onError(e,blog) {
      e.target.src = blog.fallback_img
    },

    /**
    Fetches entries for user interests (if populated),
    falling back to Lately blog entries
    **/
    async fetchBlogs(ev) {
      this.busy = true
      this.searchMsg = ''
      if ( ev ) {
        ev.stopPropagation()
        ev.preventDefault()
      }
      try {
        const blogs = await actions.fetchBlogEntries(4,this.model.search)
        this.blogs = blogs.slice(0,6)
        if ( !this.blogs.length ) this.searchMsg = `No matches - please try a different query. `
      } catch ( err ) {
        this.$toasted.error('Unable to fetch blog entries at this time')
      } finally {
        this.busy = false
      }
      return false
    },

    clearSearch() {
      this.model.search = ''
      this.$refs.search.focus()
    },

    selectBlog(blog) {
      this.model.blog = blog
      this.model.type = 'url'
      this.model.text = blog.article.article_url
      this.generating = true;
      Vue.nextTick(()=>{
        this.$emit('on-generate')
      })
    }

  },

  watch: {
    'model.search'() {
      if ( this.model.search.length ) {
        this.fetchBlogs()
      } else this.blogs = []
    },
    'model.text'() {
      this.pasted = this.pasted && this.model.text.length 
      this.composed = this.composed && this.model.text.length       
      this.model.valid = false
      switch (this.state) {
        case 'url': 
        case 'search': {
          if ( this.model.text.length ) {
            const urls = linkify.find( this.model.text ).filter((e)=>{return e.type==='url'})
            this.model.valid = urls.length>0
          } else this.model.valid = false
          break;
        }
        case 'paste': 
        case 'prompt': {
          this.model.valid = this.model.text.length >= 1000
          break;
        }        
      }

      if ( this.model.valid ) {
        this.$emit('on-text', {
          type: this.model.type,
          text: this.model.text,
          link: this.model.link,
          valid: true
        })
      } else {
        this.$emit('on-text', {
          type: this.model.type,
          text: '',
          link: '',
          valid: false
        })
      }
    }
  },

  components: {
    GPT
  }

}
</script>

<style lang="scss">

.generate-from-text {

  .actions {

      margin-right: 10px!important;
      button {
        margin-left: 20px;
        background-color: #20C763;
      }

      .submit {
        width: 150px;
        border-radius: 20px;
      }

      button.btn-link {
        background-color: transparent;
      }
    }  

  .select {
    h3 {
      font-weight: bold
    }
    p {
      margin: 0px 10px 30px 10px;
    }

    .source-type {
      .content {
        img {
          margin:5px;
          width: 80px;
        }
        h4 {
          margin-top: 5px;
          font-weight: bold;
        }
        background-color: white;
        height: 220px;
        padding: 15px 10px 20px 10px;
        border-radius: 10px;
        border: 2px solid lightgrey;
        box-shadow: 2px 2px 2px lightgrey;    
      }
    }
  }

  .url {
    input {
      border-radius: 20px;
      display: inline;
    }
    input:focus {
      box-shadow: none;
      border: 1px solid lightgrey;
      outline: none !important;
    }            
  }

  .paste {

    span.minimum-length-warning {
      padding-left:15px;
    }

    .body {

      border-radius: 15px;
      border: 1px solid lightgrey;
      box-shadow: 2px 2px 2px lightgrey;    

      .editor {
        padding: 10px 10px 40px 10px;
        border-radius: 15px 15px 0px 0px;
        background-color: white;
        textarea {
          border: 0px solid white;
          resize: none;          
        }
        .counter {
          padding: 5px 15px 5px 5px;
        }
        input:focus, textarea:focus {
          box-shadow: none;
          border: 0px;
          outline: none !important;
        }

      }

      .link {

        padding: 20px;

        input {
          border-radius: 20px;
          box-shadow: none;
          border: 0px;
          outline: none !important;
        }
        input:focus {
          box-shadow: none;
          border: 0px;
          outline: none !important;
        }        

      }

    }
    
  }

  .prompt {

    .body {

      border-radius: 15px;
      border: 1px solid lightgrey;
      box-shadow: 2px 2px 2px lightgrey;    

      .editor {
        padding: 10px 10px 40px 10px;
        border-radius: 15px 15px 0px 0px;
        background-color: white;
        textarea {
          border: 0px solid white;
          resize: none;          
        }
        .counter {
          padding: 5px 15px 5px 5px;
        }
        button {
          width: 130px;
          border-radius: 20px;
          font-weight: bold;
          margin-right: 10px;
          color: white;
          border: 1px solid #0089FF;
          background-color: #0089FF;
        }
        input:focus, textarea:focus {
          box-shadow: none;
          border: 0px;
          outline: none !important;
        }

      }

      .link {

        padding: 20px;

        input {
          border-radius: 20px;
          box-shadow: none;
          border: 0px;
          outline: none !important;
        }
        input:focus {
          box-shadow: none;
          border: 0px;
          outline: none !important;
        }        

      }

    }

  }

  .search {
    .input-group {
      color: transparent;
      background-color: transparent;
      border: 1px solid lightgrey;
      border-radius: 20px!important;
      .input-group-append {
        color: transparent;
        border-radius: 20px 20px 20px 20px;
        background-color: white;
        .input-group-text {
          border: 0px!important;
          background-color: transparent;
        }
      }
    }
    input {
      border: 0px;
      border-radius: 20px;
      background-color: white;
      display: inline;
    }
    input:focus {
      box-shadow: none;
      outline: none !important;
    }         
  }  

  .section {

    padding: 20px;
    border-radius: 5px;

    p.actions {
      margin: 10px 0px 0px 0px!important;
    }

    p.heading {
      font-size: 22px;
      font-weight: bold;
    }

    p.link-description {
      padding: 15px 20px 10px 20px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: normal;
    }

    .content {
      width: 100%!important;

      a {
        display: block;
        padding-top: 15px!important;
      }

    }

    button.generate {
      width: 150px;
      margin-left: 10px;
      border-radius: 20px;
      background-color: #20C763;
    }

  }

  .search-results {

    .heading {
      margin: 0px;
    }

    .no-results {
      margin-left: 30px;
    }

    .blog-entry {

      margin-bottom: 10px;
      border: 1px solid lightgrey;
      border-radius: 20px;

      img {
        width: 100%;
        max-height: 300px;
        border-radius: 15px 15px 0px 0px;
      }

      .creator {
        color: grey;
      }

      .content {
        padding: 20px;
        h4 {
          margin-top: 20px;
          font-size: 16px;
          font-weight: bold;
        }
        button {
          margin-top: 20px;
          width: 200px;
          border-radius: 20px;
          background-color: #20C763;
        }
      }
    }

    .blog-entry:hover {
      background-color: #f5f3f2;
    }

  }

}

</style>
