<template>

  <b-overlay :show="busy">

    <div v-if="dashboard && publisher" class="signup-page publisher-intro">

      <div v-if="publisher.id !== 'lately' && !user.onboarding.publisher">
        <div class="row">
          <div class="col-md-12" >
            <h3>Congrats on creating your Lately account! <br> Now, let's connect Lately to {{user.signup.publisher.id|partnerName}}.</h3>
          </div>
        </div>

      </div>

      <div class="lately" v-if="publisher.id === 'lately'">

        <div class="row" v-if="!user.onboarding.publisher">
          <div class="col-md-12">
            <h3>Welcome to your free trial! <br> Now, let's connect your social media channels. </h3>
          </div>
        </div>

        <!-- channel types and done button -->
        <div class="row">

          <div class="col-md-3" v-for="channel in channels" :key="channel.id">
            <div class="lately-channel d-flex flex-column">
              <div class="text-center publisher-channel-icon">
                <span class="fa-stack fa-4x">
                  <i class="fa fa-circle fa-stack-2x" :style="{'color': backgroundFor(channel.id)}"></i>
                  <i class="fab fa-stack-1x" :class="'fa-' + iconFor(channel.id)"></i>
                </span>
              </div>
              <div v-if="remaining[channel.id] || remaining.all" class="text-center connect-action" @click="connect(channel.id)" :class="channel.connected? 'connected' : ''">
                <span>{{channel.connected ? 'CONNECTED' : 'CONNECT ' + labelFor(channel.id) }}</span>
              </div>
              <div v-else-if="channel.connected" class="text-center connect-action connected disabled">
                <span>CONNECTED</span>
              </div>
              <div v-else class="text-center connect-action disabled">
                <span>UPGRADE</span>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <ChannelsRemaining :remaining="remaining" :selections="selections" />
          </div>

        </div>

        <div class="row" v-if="channelsConnected && remaining.all && !user.onboarding.publisher">
          <div classs="col-md-12">
            <p class="info" ><i><b>Great!</b> connect your other channels now or connect them later.</i> </p>
          </div>
        </div>

      </div>

      <!-- if not lately show connect link -->
      <div class="row" v-if="publisher.id !== 'lately'">

        <div class="col-md-3 connect-publisher-property">

          <div class="publisher-logo text-center">
            <img :src="logoFor(publisher)" width="150px" >
            <b-button @click="select($event,publisher,true)" block class="publisher-connect" variant="success" >
              CONNECT
            </b-button>
          </div>

        </div>

        <div class="col-md-5 connect-publisher-copy">
          <p class="publisher-copy"> When you connect your Lately account to {{publisher.id|partnerName}}, our AI will study what words and phrases
            best resonate with your target audience by analyzing the analytics of any social account you have connected to {{publisher.id|partnerName}}.
          </p>
        </div>

      </div>

      <!-- fallback navigation to select other publisher -->
      <div class="row connect-additional-options" v-if="!dashboard.channels.length && !isIframe('hootsuite') && includes('connect-third-party')">

        <div class="col-md-12">
          <p>
            <span v-if="publisher.id!=='lately'">Don’t have a {{publisher.id|partnerName}} account? No worries, switch to</span>
            <span v-else>Switch to </span>
            <span v-for="(otherPublisher,idx) in otherPublishers" :key="otherPublisher.id">
              <a href="#" @click="select($event,otherPublisher)" >
                {{ otherPublisher.id|partnerName }}
              </a>{{idx!==otherPublishers.length-1?', or' : '' }}
            </span>
            <span v-if="publisher.id!=='lately'"> instead.</span>
          </p>
        </div>

      </div>

      <div class="row" v-if="publisher.id==='lately'">

        <div class="col-md-2 publisher-complete-btn">
          <b-button :disabled="!channelsConnected" @click="complete($event,publisher)" block class="publisher-connect" variant="success" >
            {{ isOnboarded? 'NEXT' : 'ALL DONE' }}
          </b-button>
        </div>

      </div>

    </div>

  </b-overlay>

</template>

<script>

import ChannelsRemaining from './ChannelsRemaining'
import { actions, getters } from '@/services/store'
import ChannelIcon from '@/components/ChannelIcon'
import { includes } from '@/services/product'
import { media, Publishers } from '@/services/constants'
import Hootsuite from '@/services/hootsuite'

const CHANNELS = [{
    id:'twitter',
    connected:false
  },{
    id:'linkedin',
    connected:false
  },{
    id:'facebook',
    connected:false
  },{
    id:'instagram',
    connected:false
  }]

export default {

  name: 'ConnectPublisher',

  data() {
    return {
      msg: false,
      user: false,
      busy: false,
      publisher: false,
      dashboard: false,
      includes: includes,
      otherPublishers:[],
      channelsConnected: 0,
      isOnboarded: false,
      channels: CHANNELS.slice(),
      isIframe: getters.isIframe
    }
  },

  props: {
    remaining: Object,
    selections: Object
  },

  created() {

    this.updateChannelStatus()
    this.user = getters.user()
    this.dashboard = getters.dashboard()
    this.publisher = Publishers.find((p)=>{ return p.id === this.user.signup.publisher.id })

    this.otherPublishers = Publishers.filter((p)=>{ return p.enabled && p.id !== this.user.signup.publisher.id })
    if ( this.$route.query.error ) {
      this.$toasted.error(`${decodeURIComponent(this.$route.query.error)}`)
      this.$router.push({ query: {} }).catch(failure => false);
    }
  },

  methods: {

    upperCase(text) {
      return text.toUpperCase()
    },

    async updateChannelStatus() {

      if ( this.dashboard ) {

        // setup channel connected state
        this.channels.forEach((ch)=>{
          ch.connected = this.dashboard.channels.find((ch2)=>{
            return ch2.type == ch.id && !ch2.needsSelection
          })? true:false
        })

        // count connected channels
        this.channelsConnected = this.dashboard.channels.filter((ch)=>{
          return !ch.needsSelection
        }).length

        // setup onboarded state and sync user
        this.user.signup = this.user.signup || {}
        if ( this.publisher ) {
          this.publisher.onboarded = this.publisher && this.publisher.onboarded && (this.channelsConnected > 0)
        }
        this.user.signup.publisher = this.publisher
        await actions.patchUser(this.user._id,'signup.publisher',this.publisher)

      } else this.channelsConnected = 0

    },

    logoFor(publisher) {
      return require(`@/assets/integrations/${publisher.img}`)
    },

    backgroundFor(publisher) {
      return media[publisher].color;
    },

    iconFor(property) {
      switch(property) {
        case 'facebook': return property+'-f';
        case 'linkedin': return 'linkedin-in';
        case 'twitter': return 'square-x-twitter';
        default: return property;
      }
    },

    labelFor(property) {
      return property==='twitter'? 'X' : property.toUpperCase()
    },

    async select(ev,publisher,connect) {

      ev.stopPropagation()
      ev.preventDefault()

      publisher.onboarded = false
      this.publisher = publisher
      this.user.signup.publisher = publisher
      this.otherPublishers = Publishers.filter((p)=>{ return p.enabled && p.id !== this.user.signup.publisher.id })
      await actions.patchUser(this.user._id,'signup.publisher',publisher)

      if ( publisher.id === 'hootsuite' && getters.isIframe('hootsuite') && connect ) {
        const resp = await Hootsuite.syncSocial( this.dashboard._id )
        this.$router.go(this.$router.currentRoute) // refresh
      } else if ( publisher.id !== 'lately' && connect ) {
        return actions.connect(publisher.id)
      }
    },

    async connect(type) {
      this.busy = true
      return actions.connect(`lately/${type}`)
    },

    async isConnected() {
      return this.dashboard.channels.filter((ch)=>{
        return !ch.needsSelection
      }).length > 0
    },

    async complete(ev,publisher) {
      this.$emit('complete-publisher',publisher)
    },

    async portal() {
      const p = await actions.fetchStripePortalUrl()
      document.location.href = p.data.url
    },

  },

  // watch for channel connection status changes
  watch: {

    'dashboard.channels': {
      handler() {
        this.updateChannelStatus()
      },
      deep:true
    },

    dashboard() {
      this.updateChannelStatus()
    }

  },

  components: {
    ChannelsRemaining,
    ChannelIcon
  }

}
</script>

<style lang="scss" >

.publisher-intro {

  // background-color: #F7F7F7;

  h3 {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 48px;
  }

  .publisher-channel-icon {
    color: white;
    padding: 0px!important;
    margin: 48px 0px 48px 0px;
  }

  .lately {
    p.info {
      font-size: 24px;
      background-color: white;
      margin: 48px 0px 48px 0px;
      padding: 24px 0px 24px 32px;
    }
  }

  .connect-publisher-property {
    border: 1px solid grey;
    border-width: 1px 0px 1px 1px;
    border-radius: 10px 0px 0px 10px;
    padding: 15px;
    background-color: white;
    margin-top: 20px;
  }

  .connect-publisher-copy {
    background-color: white;
    border: 1px solid grey;
    border-width: 1px 1px 1px 0px;
    border-radius: 0px 10px 10px 0px;
    padding: 15px;
    padding-top: 25px;
    margin-top: 20px;
    font-size: 0.95em;
    min-height:150px;
    background-color: white;
  }

  .connect-publisher .publisher-copy {
    font-size: 0.95em;
  }

  .connect-additional-options {
    padding-top:30px;
  }

  .lately-channel {
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    background-color: white;
    .connect-action {
      cursor: pointer;
      font-size: 18px;
      font-weight: bold;
      padding: 20px;
      background-color: #F2F2F2;
      span {
        color: #008CFF
      }
    }

    .connect-action.disabled {
      cursor: default;
      background-color: grey;
      span {
        color: #FFFFFF
      }
    }

    .connect-action.connected {
      background-color: #008CFF;
      span {
        color: #FFFFFF
      }
    }
  }

  .lately-channel .publisher-channel-icon {
    padding-top:20px;
    padding-bottom:20px;
  }

  .publisher-complete-btn {
    margin-top: 70px;
  }

  .publisher-logo img {
    max-height: 60px;
  }

}


</style>
