<template>
  <div class="channels-remaining">

    <MailModal @on-mail-action="onMailAction" ttitle="New Message" tsubject="My Subscription" tmessage="I'd like to upgrade or change my subscription; please contact me." :show="showModal"/>

    <!--
    <span>Over {{overAllocated}}, Under {{underAllocated}}, Remaining {{selectionsRemaining}} </span>
    -->
    
    <!-- total channels limited plan -->
    <p class="text-center" v-if="typeof selectionsRemaining.all !== 'undefined'">
      <span v-if="!selectionsRemaining.all">
      You've reached the channel limit for your {{product.name}} plan!</a>
      </span>
      <span v-else-if="selectionsRemaining.all">
      Connect up to {{selectionsRemaining.all}} more channels with your {{product.name}} plan</a>
      </span>
      <span v-if="overAllocated.all" class="text-danger">
      You've exceeded the channel limit for your {{product.name}} subscription! Please disconnect {{Math.abs(overAllocated.all)}} channel(s). </a>
      </span>
    </p>

    <p class="text-center" v-else-if="type">
      <span v-if="selectionsRemaining[type]>0">
        Connect up to
        <span :class="selectionsRemaining[type]? 'channel-available':'channel-allocated'">{{selectionsRemaining[type]}} more {{type|channelname|titlecase}} </span>
        channels with your {{product.name}} subscription.
      </span>
    </p>
    <p class="text-center" v-else-if="Object.keys(underAllocated).length">
      Connect up to
      <span v-for="(val,type,idx) in underAllocated" :class="val? 'channel-available':'channel-allocated'">{{val}} more {{type|channelname|titlecase}}{{idx!==Object.keys(underAllocated).length-1? ',':''}} </span>
      channels with your {{product.name}} subscription.
    </p>
    <p class="text-center text-danger" v-else-if="Object.keys(overAllocated).length">
      Please disconnect
      <span v-for="(val,type,idx) in overAllocated">{{Math.abs(val)}} {{type|channelname|titlecase}}{{idx!==Object.keys(overAllocated).length-1? ',':''}} </span>
      channels for your {{product.name}} subscription.
    </p>
    <p class="text-center" v-if="product.name==='Professionally'"> If you would like to increase your channel allocation please <a :href="mailRef()">send us a note!</a> </p>
    <p class="text-center" v-else> If you would like to increase your channel allocation please send us a note to <a :href="mailRef()" >upgrade your Subscription.</a> </p>    
    <!--
    <p class="text-center" v-if="product.name==='Professionally'"> If you would like to increase your channel allocation please <a href="#" @click="showModal=true">send us a note!</a> </p>
    <p class="text-center" v-else> If you would like to increase your channel allocation please send us a note to <a href="#" @click="showModal=true" >upgrade your Subscription.</a> </p>
    -->
  </div>
</template>


<script>


import { actions, getters } from '@/services/store'
import MailModal from '@/components/MailModal'

export default {
  name: 'ChannelsRemaining',
  props: {
    type: String,
    remaining: Object,
    selections: Object,
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      product:false,
      dashboard:false,
      showModal:false,
      overAllocated: false,
      underAllocated: false,
    }
  },
  methods: {
    onMailAction(action) {
      //console.log('onMailEvent',action)
      this.showModal = false
    },
    mailRef() {
      return `mailto:support@lately.ai?subject=${encodeURIComponent("I'd like to upgrade my subscription")}&from=${encodeURIComponent(getters.user().email)}&body=${encodeURIComponent("Please contact me in order to change or upgrade my subscription")}`
    },
    async portal() {
      const mode = getters.isIframe('hootsuite')
      const p = await actions.fetchStripePortalUrl(mode)
      if ( getters.isIframe('hootsuite') ) {
        window.open(p.data.url,'_blank')
      } else document.location.href = p.data.url
    }
  },
  created() {
    this.product = getters.product()
    this.dashboard = getters.dashboard()
  },
  components: {
    MailModal
  },
  computed: {

    selectionsRemaining() {
      const remaining = Object.assign({all:0},this.remaining)

      console.log('copied remaining', JSON.stringify(remaining,0,1))

      Object.keys(this.selections).forEach((id)=>{
        const ch = this.dashboard.channels.find((ch)=>{return ch._id  === id })
        if ( ch && this.selections[id] ) {
          if ( remaining.all ) {
            remaining.all -= 1
          } else {
            remaining[ch.type] -= 1
          }
        }
      })

      Object.keys(remaining).forEach((key)=>{
        if ( !remaining[key] ) {
          delete remaining[key]
        }
      })

      // side effect
      this.underAllocated = Object.keys(remaining).filter((key)=>{
        return remaining[key] > 0
      }).reduce((acc,key) => {
        acc[key] = remaining[key]
        return acc
      },{})

      this.overAllocated = Object.keys(remaining).filter((key)=>{
        return remaining[key] < 0
      }).reduce((acc,key) => {
        acc[key] = remaining[key]
        return acc
      },{})

      console.log('computed remaining', JSON.stringify(remaining,0,1))

      return remaining
    }

  }

}
</script>

<style lang="scss" >

.channels-remaining {
  background-color: #E5E7E9;
  border: 1px solid #E5E7E9;
  border-radius: 5px;
  font-face: Noto Sans;
  font-size: 13px;
  font-weight: bold;
  padding: 10px;
  margin: 20px 0px 20px 0px;
  p {
    margin: 0px;
  }
  .channel-available {
    color:#42b983
  }
  .channel-allocated {
    color:#CD6155
  }
}

</style>
