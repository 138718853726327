<template>
  <div class="container onboard-page onboard-breakdown" v-if="ready">

    <div class="row onboard-page-header">
      <div class="col-12">
        <img src="@/assets/onboarding/onb_data.png" />
      </div>
      <div class="col-12">
        <span class="title">Your posting data:</span>
      </div>
    </div>

    <div class="row onboard-page-body breakdown-sections">

      <div class="col-11 offset-1" v-for="(section,idx) in sections" :key="idx" >

        <div class="row breakdown-section">

          <div class="col-2 breakdown-section-icon">
            <span class="fa-stack fa-2x section-icon">
              <i class="fas fa-circle fa-stack-2x" :style="{'color':colorFor(section.id)}"></i>
              <i :class="'fab fa-stack-1x fa-inverse fa-'+iconFor(section.id)"></i>
            </span>
          </div>

          <div class="col-6 breakdown-section-text">
            <p class="p1">Your average posts per day: {{section.actual|number(2)}}</p>
            <p class="p2">Eep, that's <b>{{section.percent|number(0)}}%</b> off the mark.</p>
          </div>

          <div class="col-3 breakdown-section-comparison text-center">
            <p class="secondary"><i>The most successful {{section.id|titlecase}} users post <b>{{section.best}} times per day!</b></i></p>
          </div>

        </div>
      </div>
    </div>

    <div class="row onboard-page-footer">
      <div class="col-10 offset-1 text-center">
        <b-button @click="next(-1)" class="onboard-back">BACK</b-button>
        <b-button @click="next(1)" class="onboard-continue">TELL ME MORE</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ChannelIcon from '@/components/ChannelIcon'
import { media } from '@/services/constants'

export default {

  name: 'Breakdown',

  data() {
    return {
      ready: false,
      sections:[]
    }
  },

  props: {
    stats: {
      type: Object,
      required: true
    }
  },

  created() {

    // skip step if we did not get an import
    if ( this.stats.status !== 'COMPLETED' ) {
      return this.next(1)
    }

    if ( this.stats.partners.lately ) {

      this.sections = Object.keys(this.stats.buckets).reduce((acc,key)=>{
        const bucket = this.stats.buckets[key]
        acc.push(this.statsFor(key,bucket))
        return acc;
      },[])

      this.ready = true

    } else this.next(1)

  },

  methods: {

    bestFor(id) {
      switch( id ) {
        case 'twitter': return 6;
        case 'facebook': return 4;
        case 'linkedin': return 1;
        case 'instagram': return 3;
      }
    },

    statsFor(id,bucket) {

      const best = this.bestFor(id)
      const actual = bucket.days? bucket.content/bucket.days : 0
      const percent = (1-(actual/best))*100
      const minutes = best * (percent/100) * 22

      return {
        id: id,
        best: best,
        actual: actual,
        percent: percent,
        minutes: minutes
      }

    },

    next(dir) {
      this.$emit('on-next',dir)
    },

    colorFor(ch) {
      return media[ch].color
    },

    backgroundFor(ch) {
      return media[ch].color
    },

    borderFor(ch) {
      if ( this.channel === ch ) {
        return media[ch].color
      } else return '#D6D6D6'
    },

    iconFor(ch) {
      switch(ch) {
        case 'facebook': return 'facebook-f';
        case 'linkedin': return 'linkedin-in'
        default: return ch
      }
    }

  },

  components: {
    ChannelIcon
  }

}
</script>

<style lang="scss" >

.onboard-breakdown {

  .breakdown-section {
    margin-bottom: 30px;
  }

  .breakdown-section-icon {
    font-size: 20px;
    border-radius: 20px 0px 0px 20px;
    border-width: 2px 0px 2px 2px;
    border-style: solid;
    border-color: lightgrey;
    padding: 28px 24px 24px 36px;
  }

  .breakdown-section-text {
    border-radius: 0px;
    border-width: 2px 0px 2px 0px;
    border-style: solid;
    border-color: lightgrey;
    padding: 24px 36px 0px 0px;
    font-size: 24px;
    box-shadow: 2px 2px 2px lightgrey;

    p.p1 {
      font-weight: bold;
      margin-bottom: 8px;
    }
    p.p2 {
      font-weight: normal;
      margin-top: 8px;
    }
  }

  .breakdown-section-comparison {
    background-color: #EEFBF4;
    border-radius: 0px 10px 10px 0px;
    border-width: 2px 2px 2px 0px;
    border-style: solid;
    border-color: lightgrey;
    font-size: 20px;
    padding: 24px 30px 24px 30px;
    box-shadow: 2px 2px 2px lightgrey;
    p {
      margin: 0px;
    }
  }

}

.breakdown-actions {
  margin-top: 36px;
}

</style>
