<template>

  <div class="success-indicator-v2" v-bind:class="offset? 'offset':''" v-if="show">

    <div class="text-center indicator-gauge" :key="post._id">

      <p class="indicator-title">
        AI Train-o-meter
        <i id="success-indicator-info" class="float-right fa fa-info-circle" />
        <b-popover target="success-indicator-info" custom-class="success-info" triggers="hover" placement="bottomleft">
          <p class="title"><b>Your AI Model Training Score</b></p>
          <span class="body">{{hoverBodyText}}</span>
        </b-popover>
      </p>

      <VueSvgGauge
        class="mini-gauge"
        :start-angle="-110"
        :end-angle="110"
        :value="score"
        :separator-step="25"
        :gauge-color="[{ offset: 0, color: '#de3a21'}, { offset: 50, color: '#f4c009'} , { offset: 100, color: '#0b8c5a'}]"
        :scale-interval="100"
        :inner-radius="65"
        base-color="#d0cdcd"
      >
        <div class="inner-text inner-text--3">
          <span class="variant">{{ variant }}</span>
        </div>

      </VueSvgGauge>

    </div>

    <div class="transition">
      <div class="indicator-summary">
        <span>{{ textual||'Just a moment..'}}</span>
      </div>
    </div>

    <div class="indicator-checklist">

      <transition>
        <div>
          <p  class="list-unstyled indicator-reason text-left" v-for="(reason,idx) in unsatisfied" :key="idx" >
              <span class="indicator-sentiment" :class="mapCls(reason.cls)"> <i :class="reason.icon"></i></span>
              <span class="indicator-text" v-b-tooltip.hover :title="reason.tooltip">{{reason.msg}}</span>
          </p>
        </div>
      </transition>

      <transition>
        <div v-if="showDetail" class="text-left">
          <p  class="list-unstyled indicator-reason" v-for="(reason,idx) in satisfied" :key="idx" >
            <span class="indicator-sentiment" :class="mapCls(reason.cls)"> <i :class="reason.icon"></i></span>
            <span class="indicator-text" v-b-tooltip.hover :title="reason.tooltip">{{reason.msg}}</span>
          </p>
        </div>
      </transition>

      <!--

      <hr class="section-divider"/>

      <transition>
        <WritingTips class="writing-tips" :post="post" :channels="channels" v-if="ready" />
      </transition>

      -->

    </div>

  </div>

</template>

<script>

'use strict'

import { getters, actions } from '@/services/store'
import { SESSION_KEYS } from '@/services/constants'
import Session from '@/services/session'
import WritingTips from './WritingTips'
import crypto from 'crypto';
import Vue from 'vue'

export default {

  name: 'Checklist',

  data() {
    return {
      score:0,
      variant:'',
      textual:'',
      reasons:[],
      ready:false,
      timer:false,
      parsed:[],
      edited:false,
      showDetail:true,
      originalContent:'',
      emotive:'fal fa-circle',
      hoverBodyText: `Lately’s AI gets doubly smart, doubly faster, the more you train your model!
      Follow the prompts below to increase your training score and optimize each post's ROI.`
    }
  },

  props: {
    post: {
      type: Object,
      required: true
    },
    channels: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Number,
      default: 0
    }
  },

  async created() {
    this.dashboard = getters.dashboard()
    await this.assess()
  },

  computed: {

    satisfied() {
      return this.reasons.filter((reason)=>{
        return reason.dir > 0 && !reason.hide
      })
    },

    unsatisfied() {
      return this.reasons.filter((reason)=>{
        return reason.dir <=0 && !reason.hide
      })
    }

  },

  components: {
    WritingTips
  },

  watch: {

    // reassess on post change
    post () {
      this.assess()
    },

    // wait for completion of content edit then assess
    'post.content' () {
      this.assess()
    },

    // reassess on attachments change
    'post.attachments': {

      handler() {
        this.assess()
      },
      deep: true

    }
  },

  methods: {

    mapCls(cls) {
      return cls==='text-success'? 'active':''
    },

    async assessImpl() {

      // TODO - THIS SHOULD BE IN A PARENT COMPONENT
      // conditionally assert a hash to determine edit status
      if ( !this.post.contentHash ) {
        this.post.contentHash = crypto.createHash('md5').update(this.post.content).digest("hex")
      }

      // fetch score based on content and number of attachments
      const attachments = this.post.attachments.map((a)=>{ return {_id:a._id}})
      const score = await actions.scorePost(this.dashboard._id, this.post._id, {
        edited: this.post.contentHash === crypto.createHash('md5').update(this.post.content).digest("hex"),
        content: this.post.content,
        attachments: attachments
      })

      Object.keys(score).forEach((key)=>{
        this[key] = score[key]
      })

      // set emotive result
      if ( this.score < 50 ) {
        this.textual = 'Some work to do!'
        this.variant = '🙁'
      } else if ( this.score < 60 ) {
        this.textual = 'Not bad'
        this.variant = '😶'
      } else if ( this.score < 80 ) {
        this.textual = 'Pretttty, prettty good'
        this.variant = '🙂'
      } else {
        this.textual = 'Superstar Post!!'
        this.variant = '😄'
      }

      this.ready = true

      this.$emit('on-score-updated',this.score)

    },

    async assess() {

      if ( this.timer ) {
        clearTimeout(this.timer)
        this.timer = false
      }

      this.timer = setTimeout( this.assessImpl, 500 )

    }
  }

}
</script>

<style lang="scss" >

.success-indicator-v2 {
  position: relative;
  background-color: #F4F4F4;
  border: 1px solid lightgrey;
  padding-top:18px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px lightgrey;

  .indicator-title {
    color: black;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
    padding-bottom: 14px;
    i {
      color: grey;
      margin-top: 5px;
      margin-right: -10px;
    }
  }

  .indicator-gauge {
    padding: 0px 28px 0px 28px;

  }

  .indicator-checklist {
    border-radius: 0px 0px 10px 10px;
    background-color: white;
    padding: 24px 18px 18px 18px;
  }

  .section-divider {
    border: 2px solid lightgrey;
    margin: 18px 0px 18px 0px;
  }

  .indicator-reason {
    font-size: 14px;
    margin:0px;
    v-align:middle;
    width:100%!important;
    padding-top: 5px;
  }fs

  .indicator-text {
    font-size: 14px;
    font-weight: normal;
    padding: 5px;
  }

  .indicator-sentiment {
    color: lightgrey;
    float: none;
    padding-right: 3px;
  }

  .indicator-sentiment.active {
    color: #20C763;
  }

  .results-post-success {
    font-size: 0.9em;
  }

  .results-post-success small {
    font-size: 0.9em;
  }

  .success-checklist-btn {
    margin-top: 28px;
    margin-right: 5px;
    border: 1px solid lightgrey;
    border-radius: 12px;
  }

  button.success-checklist-btn.active {
    color: #5DADE2!important;
    background-color: #20C763!important;
  }

  button.success-checklist-btn.inactive {
    color: black!important;
    background-color: lightgrey;
  }

  .writing-tips {
    margin: 0px;
    border: 0px;
  }

  .writing-tips-text {
    padding: 0px;
  }

  .variant {
    border: 0px;
    font-size:44px;
    position: relative;
    top:45px;
    left:5px;
  }

  .mini-gauge .inner-text {
      width: 95%;
      height: 95%;
  }

  .mini-gauge .inner-text span {
    margin-top:10px;
    max-width: 100px;
    text-align: center;
  }

  .transition {
    position:absolute;
    text-align: center;
    padding: 0px 12px 0px 12px;
    margin: 0px;
    width: 100%;
    top: 153px;
    left: 0px;
  }

  .indicator-summary {
    box-shadow: 2px 2px 2px lightgrey;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 10px;
    font: 12px;
    padding: 5px;
    width: 100%;
  }

}

.success-info {
  background-color:white;
  p.title {
    margin-bottom: 8px;
  }
}

.success-indicator-v2.offset {
  top: -55px;
}

</style>
