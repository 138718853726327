
'use strict'

import * as filestack from 'filestack-js';
import { actions } from './store';

export const picker = {

  pickFromCloud: (session,options,policy)=> {

    return new Promise( async (resolve,reject)=>{

      // default config goes to cdnlately-v3 bucket
      const defaults = {
        lang:'en',
        uploadConfig:{
          intelligent:true
        },
        fromSources: options.fromSources,
        accept: options.accept,
        minFiles: (options.minFiles || 1),
        maxFiles: (options.maxFiles || 1),
        storeTo: {
          container: 'cdnlately-v3', // updated
          region: 'us-east-1',
          access: 'public',
          location: 's3'
        }
      }

      //var SERVICES = ['local_file_system','googledrive', 'box', 'dropbox', 'facebook', 'instagram', 'gmail']

      options.modalSize = [1024,768]

      defaults.onUploadDone = async (results)=>{

        for (let idx in results.filesUploaded) {

          const f = results.filesUploaded[idx]

          try {
            const file = await actions.findOrCreateFile( session, f )
            if ( file ) {
              f._id = file._id;
            } else {
              console.error('Unable to find or create file for uploaded', JSON.stringify(f,0,1))
            }
          } catch( err ) {
            // what to do here
            console.error('findOrCreateFile caught',err)
            this.$toasted.error(`Failed to upload file. Please try again, or select a different file`)
          }

        }

        resolve(results)
      };

      // TODO - externalize apikey
      const client = filestack.init('Agpxl27zGSfudYQ9sfk6iz',{security:policy});
      client.picker(defaults).open()

    })

  }

}
