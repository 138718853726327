<template>

  <tr @click="onAction('select')">
    <td>{{product.name}}</td>
    <td>{{product.description}}</td>
    <td>
      <b-form-checkbox disabled size="lg" v-model="product.active" name="check-button" switch />
    </td>
    <td>
      <b-form-checkbox disabled size="lg" v-model="product.livemode" name="check-button" switch />
    </td>
  </tr>

</template>

<script>


export default {

  name: 'Product',

  props: {
    product: {
      type: Object
    },
    price: {
      type: Object
    }
  },

  methods: {
    onAction(id) {
      this.$emit('on-action', {
        id:id,
        product:this.product
      })
    }
  }

}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
