<template>

<div class="row">

  <ConfirmDialog title="Remove Product?" :click="completeRemoveProduct" :open="removeDialogOpen" />

  <div class="col-md-12 admin-section-heading">

    <h3> {{editing? product._id? 'Edit Product' : 'Create Product' : 'Product Details' }}

      <span v-if="product" class="float-right">
        <b-button v-if="product._id && !product.subscribers && editing" @click="removeDialogOpen=!removeDialogOpen" type="reset" variant="danger">Remove</b-button>
        <b-button v-if="!editing" @click="onAction({id:'unselect'})" variant="outline-primary">Back to List</b-button>
        <b-button v-if="editing" @click="toggleEdit()" variant="outline-primary">Cancel</b-button>
        <b-button v-if="!editing" @click="toggleEdit()" variant="primary">Edit</b-button>
        <b-button v-if="editing" @click="toggleEdit(true)" type="submit" variant="primary">SUBMIT</b-button>
      </span>

    </h3>

  </div>

  <b-form name="productForm" @submit="onAction('submit')" >

    <div class="row">

      <button type="submit" style="display:none" />

      <!--title, enabled, id -->
      <b-form-group class="col-md-7"
        label="Name *"
        placeholder="Enter a product name"
        description="How the product will be known at registration."
      >
        <b-form-input
          autofocus
          :disabled="!editing"
          v-model="product.name"
          type="text"
          placeholder="Enter a product name"
          :state="name"
        ></b-form-input>
        <b-form-invalid-feedback :state="name">
          Please enter a name
        </b-form-invalid-feedback>

      </b-form-group>

      <b-form-group class="col-md-4"
        label="Stripe Id"
        description="Product id in Stripe"
      >
        <b-form-input
          disabled
          v-model="product.id"
          type="text"
        ></b-form-input>

      </b-form-group>

      <b-form-group class="col-md-1"
        label="Active"
      >
        <b-form-checkbox :disabled="!editing" size="lg" v-model="product.active" name="check-button" switch />
      </b-form-group>

      <!-- description -->
      <b-form-group
        label="Description *"
        placeholder="Enter a desription"
        description="How the product will be described at registration."
      >
        <b-form-textarea
          v-model="product.description"
          rows="10"
          type="text"
          :disabled="!editing"
          placeholder="Product description"
          :state="description"
        ></b-form-textarea>
        <b-form-invalid-feedback :state="description">
          Please enter a description
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="col-md-12 admin-subsection-heading">
        <h4>Prices <small>(*edit prices in Stripe then sync)</small>
          <span class="float-right">
            <b-button v-if="editing" class="product-add-button" size="sm" @click="addPrice()">Add</b-button>
          </span>
        </h4>
      </div>
      <div class="col-md-12">
        <p class="text-danger" v-if="!product.prices.length">Product has no prices</p>
        <table class="table table-striped" style="width:100%" v-if="product.prices.length">
          <tr>
            <th style="width:20%">Amount</th>
            <th style="width:10%">Currency</th>
            <th style="width:20%">Billed</th>
            <th style="width:10%">Trial</th>
            <th style="width:35%">Stripe Id</th>
            <th style="width:5%">Active</th>
            <th></th>
          </tr>
            <Price v-for="price in product.prices" :key="price._id" :product="product" :price="price" :editing="editing" @on-action="onAction" />
        </table>
      </div>

      <!-- features -->
      <div class="col-md-12 admin-subsection-heading">
        <h4>Features
          <span class="float-right">
            <b-dropdown  v-if="editing" class="product-add-button" size="sm" id="dropdown-1" text="Add" >
              <b-dropdown-item @click="addFeature(feature)" v-for="feature in remainingFeatures" :key="feature.key">{{feature.label}}</b-dropdown-item>
            </b-dropdown>
          </span>
        </h4>
      </div>

      <div class="col-md-12">
        <p class="text-danger" v-if="!product.limits.length">Product has no features</p>
        <table class="table table-striped" style="width:100%" v-if="product.limits.length">
          <tr>
            <th style="width:50%">Feature</th>
            <th style="width:25%">Limit</th>
            <th style="width:25%">Scope</th>
            <th></th>
          </tr>
          <Feature v-for="feature in product.limits" :key="feature.id" :feature="feature" :editing="editing" @on-action="onAction" />
        </table>
      </div>
    </div>

  </b-form>

  </div>
</template>

<script>

import ConfirmDialog from '@/components/ConfirmDialog'
import { features } from '@/services/product'
import { actions } from '@/services/store'
import Feature from './Feature'
import Price from './Price'

export default {
  name: 'ProductDetailView',
  data() {
    return {
      busy:false,
      original: {},
      editing:false,
      errorMessage:'',
      remainingFeatures:[],
      removeDialogOpen:false,
    }
  },
  props: {
    product: {
      type: Object,
    }
  },

  components: {
    ConfirmDialog,
    Feature,
    Price
  },

  created() {
    if ( !this.product._id ) {
      this.editing = true
    }
  },

  methods: {

    async toggleEdit(save) {
      console.log('toggleEdit',save,this.editing)
      if ( !this.editing ) { // edit
        this.original = JSON.parse(JSON.stringify(this.product))
        this.buildRemaining()
        this.editing = true
      } else if ( !save ) { // cancel edit
        if ( this.product._id ) {
          this.product = this.original
          this.editing = false
        } else {
          this.$emit('on-action', {
            id:'unselect'
          })
        }
      } else if ( save ) { // save
        await this.createOrUpdateProduct(this.product)
        this.editing = false
      }
    },

    onAction(ev) {
      console.log('Detail.onAction',ev.id)
      switch( ev.id ) {
        case 'remove' : {
          const list = ev.kind === 'feature'? this.product.limits : this.product.prices
          const idx = list.indexOf(ev.subject)
          if ( idx !== -1 ) list.splice(idx,1)
          this.buildRemaining()
        }
        default : {
          this.$emit('on-action',{
            id:ev.id,
            product:this.product
          })
        }
      }
    },

    async createOrUpdateProduct(product) {

      console.log('createOrUpdateProduct', JSON.stringify(product,0,1) )

      this.busy = true;
      this.errorMessage = '';
      const action = product._id? 'update':'create'

      try {

        if (action === 'create') {
          const newProduct = await actions.createProduct(product)
          this.onAction({id:'created',product:newProduct})
        } else {
          const resp = await actions.updateProduct(product)
          this.onAction({id:'updated',product})
        }

        this.$toasted.success(`Product ${action}d`)

      } catch(err) {
        if ( err.message ) {
          this.errorMessage = err.message;
        } else this.errorMessage = `We were unable to ${action} product - please try again in a few minutes`
      } finally {
        this.editing = false;
        this.busy = false;
      }

    },

    editproduct(product) {
      if ( product ) {
        this.product.subscribers = this.product.prices.reduce((acc,p)=>{
          acc += p.subscribers;
          return acc;
        },0)
        this.buildRemaining();
        this.editing = true;
      } else {
        this.product = product;
        this.editing = false;
      }
      this.errorMessage = false;
    },

    buildRemaining() {
      this.remainingFeatures = Object.keys(features).reduce((acc,key)=>{
        if ( !this.product.limits.find((f)=>{return f.id===key})) {
          const feature = features[key]
          feature.id = key
          acc.push(feature)
        }
        return acc;
      },[])
    },

    async completeRemoveProduct(confirm) {
      console.log('completeRemoveProduct',confirm)
      this.removeDialogOpen = false;
      if ( confirm ) {
        console.log('removing', JSON.stringify(this.product,0,1))
        await actions.removeProduct(this.product);
        this.onAction('removed')
      }
      this.pendingRemoval = false;
    },

    addFeature(feature) {
      console.log('adding feature', JSON.stringify(feature,0,1))
      this.product.limits.unshift(feature)
      const idx = this.remainingFeatures.indexOf(feature)
      if ( idx != -1 ) this.remainingFeatures.splice(idx,1)
    },

    removeFeature(feature) {
      const idx = this.product.limits.indexOf(feature)
      if (idx!=-1) this.product.limits.splice(idx,1)
      this.buildRemaining()
    },

    addPrice() {
      this.product.prices.push({
        id:'',
        interval:'month',
        currency:'usd',
        amount:9.99,
        trial_period_days:7
      })
    },

    removePrice(price) {
      const idx = this.product.prices.indexOf(price)
      if ( idx != -1 ) this.product.prices.splice(idx,1)
    },

  },

  computed: {
    price() { return true }, //(this.product.price>0) },
    name() { return (this.product.name.trim().length >0) },
    interval() { return true }, //(this.product.interval.trim().length >0) },
    description() { return (this.product.description.trim().length >0) },
    subscription() { return (this.product.id.trim().length>0) },
  },

}
</script>

<style lang="scss" >
h1.msg {
  color: $gray-900;
}
</style>
