<template>

  <div class="container analytics" v-if="results">

    <!-- header -->
    <div class="row title">

      <div class="col-md-12">

        <h4><b>Analytics</b></h4>

      </div>

    </div>

    <div class="row">

      <div class="col-md-12">

        <b-form inline style="float-right">

          <div class="analytics-date">
            <span>From: </span><b-form-datepicker id="from-datepicker" v-model="from" class="mb-2"></b-form-datepicker>
          </div>

          <div class="analytics-date">
            <span>To: </span><b-form-datepicker id="to-datepicker" v-model="to" class="mb-2"></b-form-datepicker>
          </div>

          <b-button variant="primary" @click="submit()">Update</b-button>

          <b-button-group class="pull-right analytics-source-selection" v-if="includes('send-third-party')">
            <b-button @click="select('partner')" :variant="selected==='partner'? 'primary':''">{{partner|partnerName}}</b-button>
            <b-button @click="select('export')" :variant="selected==='export'? 'primary':''">Exported</b-button>
          </b-button-group>

        </b-form>

      </div>

    </div>

    <div class="row analytics-row">

      <div class="col-md-5 analytics-section analytics-posts-sent" v-if="selected==='partner'">

        <h4><b>Posts Sent to {{partner|partnerName}}</b></h4>
        <h3><i class="fa fa-paper-plane text-success" /><b> {{results.sent.partner.all}}</b></h3>
        <br/>
        <column-chart :colors="[['#34B1F3', '#0078EC','#008CFA','#D255C1']]" height="300px" style="position:abolute;top:0px;" class="analytics-chart"
        :data="sent.partner"></column-chart>

      </div>

      <div class="col-md-5 analytics-section analytics-posts-sent" v-if="selected==='export'">

        <h4><b>Posts Exported</b></h4>
        <h3><i class="fa fa-paper-plane text-success" /><b> {{results.sent.export.all}}</b></h3>
        <br/>
        <column-chart :colors="[['#34B1F3', '#0078EC','#008CFA','#D255C1']]" height="300px" style="position:abolute;top:0px;" class="analytics-chart"
        :data="sent.export"></column-chart>

      </div>

      <div class="col-md-5">

        <div class="analytics-section analytics-time-saved" style="height:38%">
          <b>Time saved in {{initial? 'these last' : 'the last'}} {{interval}} days</b>
            <h1><b><i class="fa fa-fw fa-clock"/>
            <span>{{timeSaved.hours}}h</span>
            <span> {{timeSaved.minutes}}m</span>
          </b></h1>
        </div>

        <div class="analytics-section analytics-post-quality" v-if="selected==='partner'">
          <h4><b>Posts Sent Quality</b></h4>
          <p v-if="!quality.partner.length">You haven't sent any posts yet</p>
          <pie-chart v-if="quality.partner.length" height="200px"
                     :colors="['#21915B', '#F7BA39','#F38C36','#E8492E']"
                     :data="quality.partner" legend="right"></pie-chart>
        </div>

        <div class="analytics-section analytics-post-quality" v-if="selected==='export'">
          <h4><b>Posts Exported Quality</b></h4>
          <p v-if="!quality.export.length">You haven't exported any posts yet</p>
          <pie-chart v-if="quality.export.length" height="200px"
                     :colors="['#21915B', '#F7BA39','#F38C36','#E8492E']"
                     :data="quality.export" legend="right"></pie-chart>
        </div>

      </div>

      <div class="col-md-2">

        <div class="analytics-section">
          <small>Total Posts Generated</small>
          <h1><b><i class="text-success fa fa-fw fa-xs fa-layer-group"/> {{results.generated.all}}</b></h1>
        </div>

        <div class="analytics-section">
          <small>Results Left to Review</small>
          <h1><b><i class="text-info fa fa-fw fa-xs fa-edit"/> {{results.remaining}}</b></h1>
        </div>

        <div class="analytics-section">
          <small>Text Sources Used</small>
          <h1><b><i class="text-warning fa fa-fw fa-xs fa-align-left"/> {{results.sources.text+results.sources.url}}</b></h1>
        </div>

        <div class="analytics-section">
          <small>Video Sources Used</small>
          <h1><b><i class="text-danger fa fa-fw fa-xs fa-play"/> {{results.sources.video}}</b></h1>
        </div>

      </div>

    </div>

  </div>

</template>

<script>

import { actions, getters } from '@/services/store'
import { includes } from '@/services/product'
const moment = require('moment')

export default {

  name: 'Analytics',

  data() {
    return {
      to: false,
      from: false,
      defaultTo: false,
      defaultFrom: false,
      initial: false,
      partner: false,
      interval: '30',
      results: false,
      includes: includes,
      selected: includes('send-third-party,send-lately')? 'partner' : 'export',
      sent:{
        partner:false,
        export:false
      },
      quality:{
        partner:[],
        export:[]
      },
      timeSaved: {
        hours:'',
        minutes:''
      },
    }
  },

  created() {
    this.initial = true
    this.partner = getters.partner()
    this.to = moment().toDate(),
    this.defaultTo = moment(this.to).toDate()
    this.from = moment().subtract(30,'days').toDate()
    this.defaultFrom = moment(this.from).toDate()
    this.submit()
  },

  methods: {

    select(source) {
      this.selected = source
    },

    async submit() {

      this.interval = moment(this.to).diff(moment(this.from),'days')

      try {

        const dashboard = getters.dashboard()

        // fetch analytics
        this.results = await actions.fetchAnalytics( dashboard._id, moment(this.from).format('YYYY-MM-DD'), moment(this.to).format('YYYY-MM-DD') )
        // console.log('analytics queried', JSON.stringify(this.results,0,1) )

        // different status messages for initial and subsequent queries
        const sameFrom = moment(this.from).isSame(moment(this.defaultFrom),'day')
        const sameTo = moment(this.to).isSame(moment(this.defaultTo),'day')
        this.initial = ( sameFrom && sameTo )

        // prepare time saved
        const baseline = moment().startOf('day')
        baseline.add( this.results.generated.all * 12, 'minutes' )
        this.timeSaved.hours = parseInt(baseline.format('H'))
        this.timeSaved.minutes = parseInt(baseline.format('m'))

        // prepare sent to partner data series
        this.sent.partner = Object.keys(this.results.sent.partner).reduce((acc,key)=>{
          if ( key !== 'all' ) {
            acc.push([this.$options.filters.titlecase(key),this.results.sent.partner[key]])
          }
          return acc
        },[])

        // prepare sent to export data series
        this.sent.export = Object.keys(this.results.sent.export).reduce((acc,key)=>{
          if ( key === 'all' ) {
            acc.push([this.$options.filters.titlecase(key),this.results.sent.export[key]])
          }
          return acc
        },[])

        // prepare partner quality data series
        let bucket = this.results.quality.partner
        let quality = Object.keys(bucket).reduce((acc,key)=>{
          if ( key !== 'ALL' ) {
            acc[key] += bucket[key]
            acc['ALL'] += bucket[key]
          }
          return acc
        },{ A:0, B:0, C:0, D:0, ALL:0 })


        if ( quality.ALL ) {

          this.quality.partner = Object.keys(quality).reduce((acc,key)=>{

            if ( key !== 'ALL' ) {

              const val = quality[key]
              const proportion = Math.round( (quality[key] / quality.ALL) * 100 )
              const label = key === 'A'? 'Superstar Posts' :
                            key === 'B'? 'Not bad' :
                            key === 'C'? 'Pretty good' : 'Some work to do!'
              acc.push([`${proportion}% ${label}`,val])

            }

            // const label = `${}`
            return acc

          },[])

        } else this.quality.partner = []

        // prepare export quality data series
        bucket = this.results.quality.export
        quality = Object.keys(bucket).reduce((acc,key)=>{
          if ( key !== 'ALL' ) {
            acc[key] += bucket[key]
            acc['ALL'] += bucket[key]
          }
          return acc
        },{ A:0, B:0, C:0, D:0, ALL:0 })

        if ( quality.ALL ) {

          this.quality.export = Object.keys(quality).reduce((acc,key)=>{

            if ( key !== 'ALL' ) {

              const val = quality[key]
              const proportion = Math.round( (quality[key] / quality.ALL) * 100 )
              const label = key === 'A'? 'Superstar Posts' :
                            key === 'B'? 'Not bad' :
                            key === 'C'? 'Pretty good' : 'Some work to do!'
              acc.push([`${proportion}% ${label}`,val])

            }

            // const label = `${}`
            return acc

          },[])

        } else this.quality.export = []

      } catch (err) {
        console.error(err)
        this.$toasted.error(`Apologies but we're not able to fetch your analytics at the moment`)
      }

    }

  }

}

</script>

<style lang="scss" >

.analytics {
  height: 100%!important;
}

.analytics .title{
  margin-bottom: 20px;
}

.analytics-date {
  display: inline;
  margin-right: 10px;
  padding-top: 10px;
}

.analytics-chart {
  height: 100%;
}

.analytics-posts-sent {
  min-height: 400px;
  padding: 10px;
}

.analytics-row {
  margin-top: 20px;
  height: 100%!important;
}

.analytics-section {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.analytics-time-saved {
  background: #00c66b;
  color: white;
}

.analytics-source-selection {
  position: absolute;
  right: 20px;
}

</style>
