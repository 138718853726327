
'use strict'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '@/views/Admin'
import Login from '@/views/Login'
import Close from '@/views/Close'
import Logout from '@/views/Logout'
import Password from '@/views/Password'
import Subscription from '@/views/Subscription'
import KeyMessages from '@/components/KeyMessages'

import Dash from '@/components/Dashboard'
import TeamView from '@/components/TeamView'
import Goals from '@/components/GoalsEditor'
import Results from '@/components/ResultsView'
import Default from '@/layouts/default/Default'

import Session from '@/views/Session'
import Outbox from '@/components/Outbox'
import Logger from '@/services/logger'
import SignupWizard from '@/components/SignupWizard'
import ArticlesView from '@/components/ArticlesView'
import SettingsView from '@/components/SettingsView'
import SchedulerView from '@/components/SchedulerView'
import AnalyticsView from '@/components/AnalyticsView'
import GatherWebsite from '@/components/GatherWebsite'
import PublisherView from '@/components/PublisherView'
import InvitationView from '@/components/InvitationView'
import SessionHistory from '@/components/SessionHistory'
import GeneratorLauncher from '@/components/GeneratorLauncher'

import { actions, getters, authenticated, admin } from '@/services/store.js';

Vue.component('LayoutDefault', Default)

Vue.use(VueRouter)

const routes = [
 {
    path: '/:dashboardId/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAdmin: true,
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/settings',
    name: 'Settings',
    component: SettingsView,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId',
    name: 'Dash',
    component: Dash,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/start',
    name: 'Start',
    component: GeneratorLauncher,
    meta: {
      requiresAuth: true,
      layout: 'default',
      backgroundColor: 'white'
    }
  },
  {
    path: '/:dashboardId/messaging',
    name: 'Messaging',
    component: KeyMessages,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/articles',
    name: 'Articles',
    component: ArticlesView,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/posts',
    name: 'Posts',
    component: Outbox,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/invitation/:invitationId',
    name: 'Invitation',
    component: InvitationView,
    meta: {
      requiresAuth: false,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/team',
    name: 'Team',
    component: TeamView,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/publisher',
    name: 'Publisher',
    component: PublisherView,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/website',
    name: 'Website',
    component: GatherWebsite,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/goals',
    name: 'Goals',
    component: Goals,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/drafts',
    name: 'Drafts',
    component: SchedulerView,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/results/:sessionId',
    name: 'Results',
    component: Results,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/sources',
    name: 'Sources',
    component: SessionHistory,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/:dashboardId/analytics',
    name: 'Analytics',
    component: AnalyticsView,
    meta: {
      requiresAuth: true,
      layout: 'default',
    }
  },
  {
    path: '/app/close',
    name: 'Close',
    component: Close,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/app/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/app/password',
    name: 'Change Password',
    component: Password,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/app/signup',
    name: 'Signup',
    component: SignupWizard,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/app/subscription',
    name: 'Subscription',
    component: Subscription,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/app/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      layout: 'default'
    }
  },

  {
    path: '/:dashboardId/generate/:sessionId/:count',
    name: 'Generate Content',
    component: Session, // () => import(/* webpackChunkName: "generator" */ '@/views/Session'),
    meta: {
      requiresAuth: true,
      layout: 'default'
    }
  },
  { path: '*', redirect: '/app/login' }
]

const router = new VueRouter({
  linkActiveClass: 'active',
  //mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.afterEach( async (to,from,next)=> {
  if ( to && !to.path.startsWith('/app/') && authenticated() ) {
    actions.setLastUrl( to.path )
  }
})

/**
 * Route guard handles member and admin routes
 * and will pre-empt route changes to signup
 * or onboarding states if necessary
 **/

router.beforeEach( async (to, from, next) => {

  try {

    // log page view
    if ( to.path !== from.path ) {
      Logger.pageViewed( to.name )
    }

    document.body.classList.remove('no-nav')

    // router guard
    let preempt = await actions.init(false,to.path)

    if (to.matched.some(record => record.meta.backgroundColor)) {
      document.body.style.backgroundColor = to.meta.backgroundColor || 'white'
    } else {
      document.body.style.backgroundColor = '#F9F9F9'
    }

    // find a matching route requiring auth
    if(to.matched.some(record => record.meta.requiresAuth)) {

      // guard routes requiring auth
      if ( authenticated() ) {

        // if the desired route was pre-empted by the guard go there
        if ( preempt && preempt != to.path ) return next( preempt )

        // admin route guard
        if (to.matched.some(record => record.meta.requiresAdmin)) {
          if ( admin() ) {
            next()
          } else next('/error?message=Denied')
        } else next()

      } else next('/app/login')

    }

    // a public route can also be pre-empted for hootsuite embedded use
    else if ( preempt && preempt !== to.path && to.path !== '/app/logout' ) {
      if ( to.path === '/app/login' && getters.isIframe('hootsuite') ) {
        return next( preempt )
      } else return next()
    }

    else next()

  } catch( err ) {
    console.error('Router caught', err)
  }

})

export default router
