<template>

  <div class="list-item-actions" v-if="post">

    <p class="text-danger" v-for="error in errors">
      <small>{{error.message}}</small>
    </p>

    <p class="text-left actions" v-if="!busy">
      <a v-if="partner === 'lately' && post.status !== 'T' && post.status !== 'P' && !errors.length" variant="link" class="primary" @click="onAction('send')">Publish to {{post.contentType|channelname|titlecase}}</a>
      <a v-if="partner === 'lately' && post.status !== 'P' && post.publishAt && !errors.length" variant="link" class="primary" @click="onAction('reschedule')">Reschedule</a>
      <a v-if="partner === 'lately' && post.status !== 'P' && !post.publishAt && !errors.length" variant="link" class="primary" @click="onAction('self-schedule')">Schedule</a>
      <a v-if="partner !== 'lately' && post.status !== 'T' && post.status !== 'P' && !errors.length" variant="link" class="primary" @click="onAction('send')">Send to {{partner|titlecase}}</a>
      <a v-if="post.status !== 'T' && post.status !== 'P'" variant="link" class="default" @click="onAction('edit')">Edit</a>
      <a v-if="!publishing" variant="link" class="default" @click="onAction('remove')">Trash</a>
    </p>

  </div>

</template>

<script>

import { getters } from '@/services/store'

export default {
  name: 'Actions',
  data() {
    return {
      msg:'',
      actions:[],
      busy:false,
      partner:'',
      sendMessage:''
    }
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      default: false
    },
    publishing: Boolean
  },

  watch: {
    'post.attachments'() {
      try {
      this.busy = this.post.attachments.filter((a)=>{
        return a.mimetype && a.mimetype.startsWith('video') && a.clip && a.clip.tStart && a.clip.duration && a.status !== 'completed'
      }).length
      } catch( err ) {
        console.error('caught', err)
      }
    },
  },

  created() {

    this.partner = getters.partner()
    this.sendMessage = this.partner === 'lately'? 'Publish' : 'Send'

    this.busy = this.post.attachments.filter((a)=>{
      return a.mimetype && a.mimetype.startsWith('video') && a.clip && a.clip.tStart && a.clip.duration && a.status !== 'completed'
    }).length

    if ( this.post.publish_err && this.post.publish_err.includes('401') && this.post.channel_meta ) {
      this.msg = 'Please reconnect this channel.'
      this.actions.unshift({
        id:'send',
        text: 'RESEND',
        variant: 'link',
        title:`Send this post to ${this.post.channel_meta.partner}`
      })
    } else if ( this.post.status === 'D' || this.post.status === 'F' && this.post.channel_meta ) {
      this.msg = this.post.publish_err || this.post.err
      this.actions.unshift({
        id:'send',
        text: 'RESEND',
        variant: 'link',
        title:`Send this post to ${this.post.channel_meta.partner}`
      })
    }
  },
  methods: {
    onAction(action,args) {
      this.$emit('on-action', {
        action: action,
        post: this.post
      })
    }
  }
}
</script>

<style lang="scss" >

.list-item-actions {

  .actions {
    a {
      margin-right: 28px;
    }
    a.default {
      color: #ababab!important;
    }
    a.primary {
      color: #008CFF;
    }
  }
}

</style>
