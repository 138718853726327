
'use strict'

import Vue from 'vue';
import App from './App.vue';
import moment from 'moment';
import Toasted from 'vue-toasted';
import VueAuthHref from 'vue-auth-href';
import VueSvgGauge from 'vue-svg-gauge'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false

import router from './services/router.js';
import { store } from './services/store.js';

console.log(document.referrer)

// protected link downloads
Vue.use(VueAuthHref)

// slider for goals, video editor
import VueSlider from 'vue-slider-component'
Vue.component('VueSlider', VueSlider);
import 'vue-slider-component/theme/default.css'

const toastPosition = store.isMobile? 'top-right' : 'bottom-right'

// Toast - https://github.com/shakee93/vue-toasted#install-using-npm
Vue.use(Toasted,{
  iconPack:'fontawesome',
  position: toastPosition,
  keepOnHover:true,
  fullWidth:false,
  duration:5000,
  theme:'bubble'
})

// bootstrap
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// for success indicator
Vue.use(VueSvgGauge)

// for analytics
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'

Vue.use(Chartkick.use(Chart))

/**
Filters
**/

Vue.filter('date', function(value,format='MM/DD/YYYY hh:mm a') {
  if (value) {
    return moment(String(value)).format(format)
  }
})

Vue.filter('unix', function(value,format='MM/DD/YYYY hh:mm a') {
  if (value) {
    return moment.unix(parseInt(value)).format(format)
  }
})

Vue.filter('number', function(number,digits=2) {
  return Intl.NumberFormat().format(number.toFixed(digits));
})

Vue.filter('magnitude', function (item,precision) {
  function round(number){
      precision = precision || 0;
      var multiplier = Math.pow(10, precision);
      return (Math.round(number * multiplier) / multiplier);
  }
  if ( typeof item == 'number' ) {
    item = Math.abs(item)
    if ( item >= 1000000 ) return (round(item/1000000) + 'M')
    else if ( item >= 1000 ) return (round(item/1000) + 'K')
    else return Math.round(item)
  } else return item
})

const titleCase = function (value,separator) {
    return (value || ' ').split(separator||' ').map(function(s) {
        try {
          return s.length > 2? (s[0].toUpperCase()+s.substring(1)) : s
        } catch(err) {
          console.error(err,value,separator)
        }
    }).join(' ')
}
Vue.filter('titlecase', titleCase )

const channelName = function (value='') {
  return value.toLowerCase()==='twitter'? 'X' : value
}
Vue.filter('channelname', channelName )

Vue.filter('upperCase', function(value='') {
  return value.toUpperCase()
})

Vue.filter('lowerCase', function(value='') {
  return value.toLowerCase()
})

// encode image with thumbnail
Vue.filter('thumbnail',(file,policy)=>{
  const handle = file.url.split('/').pop()
  return `https://process.filestackapi.com/Agpxl27zGSfudYQ9sfk6iz/security=policy:${policy.policy},signature:
  ${policy.signature}/resize=width:300/https://cdn.files.trylately.com/${handle}`
})

Vue.filter('round', function (item,precision) {
  function round(number){
      precision = precision || 0;
      var multiplier = Math.pow(10, precision);
      return (Math.round(number * multiplier) / multiplier);
  }
  if ( typeof item == 'number' ) {
    return round(item)
  } else return item
})

Vue.filter('timestamp', function(value,format) {
    var seconds = Math.floor(value)
    var millis = (value - seconds) * 1000
      var d = moment().startOf('day').seconds(seconds).milliseconds(millis)
      let val = format? d.format(format) : d
      val = val.startsWith('0:')? val.replace('0:','') : val
      return val
})

Vue.filter('status', function (status,partner) {
  const to = (partner && partner !== 'lately'? ` to ${partner} ` : '')
  switch (status) {
    case 'F': return 'failed'
    case 'T': return `sent ${to}`
    case 'P': return 'published'
    case 'D': return 'draft'
    case 'S': return 'scheduled'
    default: return '...'
  }
})

Vue.filter('attachmentStatus', function (attachment) {
  switch (attachment.status) {
    case 'processing_video': return 'processing media'
    case 'pending_video_extraction': return 'awaiting media processing'
    case 'failed': return `media processing failed`
    case 'completed': return ''
    default: return status
  }
})

Vue.filter('partnerName', function(partner) {
  if ( partner === 'hootsuite' ) {
    return 'Hootsuite'
  } else if ( partner === 'hubspot' ) {
    return 'HubSpot Marketing Hub'
  } else if ( partner === 'lately' ) {
    return 'Lately'
  } else if ( partner === 'sprinklr' ) {
    return 'Sprinklr'
  }
})

// encode url with policy & signature
Vue.filter('preview', function(file,policy) {
  if ( file ) {
    try {
      if ( file.url.split('policy=').length > 0 && file.url.split('signature=').length > 0 ) {
        file.url = file.url.split('?')[0]
      } else if ( file.url.split('security=').length > 0 ) {
        file.url = file.url.split('security=')[0]
      }
      return file.url.includes('cdnlately.s3')? file.url : `${file.url}?signature=${policy.signature}&policy=${policy.policy}&cache=false`
    } catch( err ) {
      console.error(`unable to preview ${JSON.stringify(file)} : ${err}`)
      return '';
    }
  } else return ''
} )

Vue.filter('currency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
  });
  return formatter.format(value);
});

Vue.filter('truncate', function(value='',length, clamp='...') {
  return value.length > length? value.substring(0,length) + clamp : value
});

Vue.filter('duration', function (ms) {
  ms = ms < 500? ms * 1000 : ms // some are not normalized to ms
  return moment.utc(ms).format('mm:ss');
})

/**
End Filters
**/

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
