
 const IMAGES = ['image/*']
 const VIDEOS = ['video/*','.mov','.mp4']

 const acceptable = {
   twitter: IMAGES.concat(VIDEOS),
   facebook: IMAGES.concat(VIDEOS),
   instagram: IMAGES.concat(VIDEOS),
   pinterest: IMAGES,
   linkedin: IMAGES.concat(VIDEOS),
   youtube: VIDEOS,
 }

 const maxImages = {
   twitter:4,
   linkedin:9,
   facebook:8,
   instagram:1,
   pinterest:1,
   youtube:0,
 }

 const maxAudios = {
   twitter:0,
   linkedin:0,
   facebook:0,
   instagram:0,
   pinterest:0,
   youtube:0,
 }

 const maxVideos = {
   twitter:1,
   linkedin:1,
   facebook:1,
   instagram:1,
   pinterest:0,
   youtube:1,
 }

 const minImages = {
   twitter:0,
   linkedin:0,
   facebook:0,
   instagram:0,
   pinterest:0,
   youtube:0
 }

 const minVideos = {
   twitter:0,
   linkedin:0,
   facebook:0,
   instagram:0,
   pinterest:0,
   youtube:1
 }

 const imageSizes = {
  twitter:0,
  linkedin:0,
  facebook:0,
  instagram:1,
  pinterest:0,
  youtube:0
 }

 const videoSizes = {
  twitter:0,
  linkedin:0,
  facebook:0,
  instagram:1,
  pinterest:0,
  youtube:0
 }

const TEXT_SOURCES_FILTER_DEFS = {
  reject: ['facebook','google'],
  message: `Sorry, that doesn't look like a blog post! Please try linking to a publicly available post.`
}

/**
 * Assumes url is valid!
 **/
export const text_sources_domain_filter = (url) => {
  const host = new URL(url).host
  const blocked = TEXT_SOURCES_FILTER_DEFS.reject.filter((s)=>{ return host === s || host.includes(s)  })
  return blocked.length? TEXT_SOURCES_FILTER_DEFS.message : ''
}

export const media = {

	facebook : {
    label: 'Facebook',
		maxLength: 2000,
		maxAttachments:8,
		minAttachments:0,
    minImages: minImages.facebook,
    maxImages: maxImages.facebook,
    minVideos: minVideos.facebook,
    maxVideos: maxVideos.facebook,
    maxAudios: maxAudios.facebook,
    mimetypes: acceptable.facebook,
    maxImageSize: imageSizes.facebook,
    maxVideoSize: videoSizes.facebook,
		icon: 'fab fa-facebook',
		color: '#3b5998'
	},
	twitter: {
    label: 'Twitter',
		maxLength: 280,
		maxAttachments:4,
		minAttachments:0,
    minImages: minImages.twitter,
    maxImages: maxImages.twitter,
    minVideos: minVideos.twitter,
    maxVideos: maxVideos.twitter,
    maxAudios: maxAudios.twitter,
    mimetypes: acceptable.twitter,
    maxImageSize: imageSizes.twitter,
    maxVideoSize: videoSizes.twitter,
		icon:  'fab fa-twitter',
		color: '#14171A'
	},
	instagram: {
    label: 'Instagram',
		maxLength: 2400,
		maxAttachments:1,
		minAttachments:1,
    minImages: minImages.instagram,
    maxImages: maxImages.instagram,
    minVideos: minVideos.instagram,
    maxVideos: maxVideos.instagram,
    maxAudios: maxAudios.instagram,
    mimetypes: acceptable.instagram,
    maxImageSize: imageSizes.instagram,
    maxVideoSize: videoSizes.instagram,
		icon:  'fab fa-instagram',
		color: '#C13584'
	},
	linkedin: {
    label: 'Linkedin',
		maxLength: 3000,
		maxAttachments:9,
		minAttachments:0,
    minImages: minImages.linkedin,
    maxImages: maxImages.linkedin,
    minVideos: minVideos.linkedin,
    maxVideos: maxVideos.linkedin,
    maxAudios: maxAudios.linkedin,
    mimetypes: acceptable.linkedin,
    maxImageSize: imageSizes.linkedin,
    maxVideoSize: videoSizes.linkedin,
		icon: 'fab fa-linkedin',
		color: '#0077b5'
	},
	youtube: {
    label: 'Youtube',
		maxLength: 2000,
		maxAttachments:1,
		minAttachments:1,
    minImages: minImages.youtube,
    maxImages: maxImages.youtube,
    minVideos: minVideos.youtube,
    maxVideos: maxVideos.youtube,
    maxAudios: maxAudios.youtube,
    mimetypes: acceptable.youtube,
    maxImageSize: imageSizes.youtube,
    maxVideoSize: videoSizes.youtube,
		icon:  'fab fa-youtube',
		color: '#cc181e'
	}

}

/**
 * Upcontent categories
 **/
export const Categories =  [
  {
   "title": "Apparel and Fashion",
   "url": "https://galleries.upcontent.com/galleries/ab002cd8-c2a2-4248-95ba-7d735bb88377/collections/only/selections.json"
  },
  {
   "title": "Banking",
   "url": "https://galleries.upcontent.com/galleries/3ccc938f-6f30-4d22-92a8-76b93ebcee27/collections/only/selections.json"
  },
  {
   "title": "Biotech",
   "url": "https://galleries.upcontent.com/galleries/9b4c53d9-0013-429f-ab9b-2897c819ed55/collections/only/selections.json"
  },
  {
   "title": "Broadcast Media",
   "url": "https://galleries.upcontent.com/galleries/68a82c7a-07b5-48bb-955b-1a33b8b5d3ef/collections/only/selections.json"
  },
  {
   "title": "Consumer Services",
   "url": "https://galleries.upcontent.com/galleries/009a2c76-fbbd-4f8c-b059-93de7ea8f0bb/collections/only/selections.json"
  },
  {
   "title": "Financial Services",
   "url": "https://galleries.upcontent.com/galleries/6428528a-7c49-454b-9e9a-648522deedac/collections/only/selections.json"
  },
  {
   "title": "Health, Wellness, and Fitness",
   "url": "https://galleries.upcontent.com/galleries/58e870bc-c0a7-41d7-a5b0-4897a8330a1b/collections/only/selections.json"
  },
  {
   "title": "eLearning",
   "url": "https://galleries.upcontent.com/galleries/4143900e-b883-45f0-810b-b287577c6c20/collections/only/selections.json"
  },
  {
   "title": "Accounting",
   "url": "https://galleries.upcontent.com/galleries/6411a871-94b4-4b08-bfbe-0585aedad000/collections/only/selections.json"
  },
  {
   "title": "Computer Games",
   "url": "https://galleries.upcontent.com/galleries/ed820400-5fd9-4eb7-a0ca-e0b08c8d701f/collections/only/selections.json"
  },
  {
   "title": "Computer Software",
   "url": "https://galleries.upcontent.com/galleries/f1762908-d7cd-45b8-a9b4-21c4b7440669/collections/only/selections.json"
  },
  {
   "title": "Entertainment",
   "url": "https://galleries.upcontent.com/galleries/e59f141d-3040-4bf2-984a-3d5efcd94e01/collections/only/selections.json"
  },
  {
   "title": "Information Technology ",
   "url": "https://galleries.upcontent.com/galleries/93e75d53-a301-4205-bc1d-bb987414bcf0/collections/only/selections.json"
  },
  {
   "title": "Insurance",
   "url": "https://galleries.upcontent.com/galleries/770e540d-226d-4d16-a9a5-d2864c203960/collections/only/selections.json"
  },
  {
   "title": "Law Practice",
   "url": "https://galleries.upcontent.com/galleries/59f6c8cd-f156-48cc-add8-a05ffbe12a63/collections/only/selections.json"
  },
  {
   "title": "Professional Training and Coaching",
   "url": "https://galleries.upcontent.com/galleries/2d28aa54-0139-47e4-b190-d3eb348cba9a/collections/only/selections.json"
  },
  {
   "title": "Real Estate",
   "url": "https://galleries.upcontent.com/galleries/2a079e09-41bc-4fda-94fb-72ddab48c4d4/collections/only/selections.json"
  },
  {
   "title": "Venture Capital / Private Equity",
   "url": "https://galleries.upcontent.com/galleries/f82b6b39-681d-4f26-a7f8-27662a1cf9d5/collections/only/selections.json"
  },
  {
   "title": "Marketing and Advertising",
   "url": "https://galleries.upcontent.com/galleries/ed2fffce-e895-4ae0-abf9-79be18e9680f/collections/only/selections.json"
  }
]

export const Publishers = [{
    id:'lately',
    img: 'lately_logo.jpg',
    title: `Publish with Lately`,
    enabled: true,
    connectLabel: 'SELECT',
    summary: 'Publish using',
    description: `Schedule and publish Lately's AI-generated posts right from here. <br/> Easy peasy.`
  },{
    id:'hootsuite',
    img: 'hootsuite.png',
    title: 'Publish with Hootsuite',
    enabled: true,
    connectLabel: 'SELECT',
    summary: 'Publish using',
    description: `Send Lately's AI-generated posts to your Hootsuite account for scheduling and publishing.`
  },{
    id:'hubspot',
    img: 'hubspot.png',
    title: 'Publish with HubSpot Marketing Hub',
    enabled: true,
    connectLabel: 'SELECT',
    summary: 'Publish using',
    description: `Send Lately's AI-generated posts to your HubSpot Marketing Hub account for scheduling and publishing.`
  },{
    id:'sprinklr',
    img: 'Sprinklr-Logo.jpg',
    title: 'Publish with Sprinklr',
    enabled: true,
    connectLabel: 'SELECT',
    summary: 'Publish using',
    description: `Send Lately's AI-generated posts to your Sprinklr account for scheduling and publishing.`
  }]

const SOURCES = [
	'https://www.lately.ai/blog/how-to-use-artificial-intelligence-to-skyrocket-the-effectiveness-of-your-social-media-calendar-ann-smarty-of-viral-content-bee-interviews-kately-from-lately-ai',
	'https://www.lately.ai/blog/can-ai-replace-human-writers',
	'https://www.lately.ai/blog/modernize-the-way-you-market-hubspot-inbound-magic',
	'https://www.lately.ai/blog/supercharge-your-content-with-lately-hubspot',
	'https://www.lately.ai/blog/a-i-makes-social-selling-a-breeze',
	'https://www.lately.ai/blog/turn-customers-into-fans-the-keys-to-customer-success',
	'https://www.lately.ai/blog/how-to-write-like-the-boss-katelys-writing-rules',
	'https://www.lately.ai/blog/how-a-i-is-flipping-the-marketing-world-upside-down',
	'https://www.lately.ai/blog/marketing-yourself-before-and-after-you-get-the-job-lately-live-with-madeline-mann',
	'https://www.lately.ai/blog/the-20-best-twitter-chats-for-marketers'
]

export const randomSource = ()=>{
	const min = 0, max = SOURCES.length-1
	const idx = Math.round( Math.random() * (max - min) + min )
	return SOURCES[idx]
}

export const SESSION_KEYS = {
  'SUCCESS_INDICATOR_SHOW_CHECKLIST': 'success-indicator-show-checklist'
}

const PROGRESS_MESSAGES = {

  PRE: [{
    id: 'accepted',
    checked: true,
    genre:'PRE',
    txt:'Analyzing social media data for insights'
  }],
  
  URL: [{
    id: 'watson',
    checked: false,
    genre:'URL',
    txt:'Verifying link viability'
  }],
  
  VIDEO: [{
    id: 'accepted',
    checked: false,
    genre:'VIDEO',
    txt:'Uploading video'
  },{
    id: 'ingested',
    checked: false,
    genre:'VIDEO',
    txt:'Normalizing video'
  },{
    id: 'transcribed',
    checked: false,
    genre:'VIDEO',
    txt:'Transcribing video'
  }],
  
  POST: [{
    id: 'meaningcloud',
    checked: false,
    genre:'POST',
    txt:'Assessing effective messaging for engagement'
  },{
    id: 'keywords',
    checked: false,
    genre:'POST',
    txt:'Refining brand tone and style',
  },{
    id: 'watson',
    checked: false,
    genre:'POST',
    txt:'Identifying high-performing language'
  },{
    id: 'keywords',
    checked: false,
    genre:'POST',
    txt:'Locating content sections with these elements'
  },{
    id: 'ranked',
    checked: false,
    genre:'POST',
    txt:'Turning them into resonant social media posts',
  }]
  
}

export const progressMessages = (type) =>{

  let steps

  if ( type === 'text' ) {
    steps = PROGRESS_MESSAGES.PRE.concat(PROGRESS_MESSAGES.POST)
  } else if ( type === 'url' ) {
    steps = PROGRESS_MESSAGES.PRE.concat(PROGRESS_MESSAGES.URL).concat(PROGRESS_MESSAGES.POST)
  } else if ( type === 'video' ) {
    steps = PROGRESS_MESSAGES.PRE.concat(PROGRESS_MESSAGES.VIDEO).concat(PROGRESS_MESSAGES.POST)
  }

  // crude deep clone
  steps = JSON.parse(JSON.stringify(steps))

  return steps

}