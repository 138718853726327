<template>

  <b-modal static lazy content-class="schedule-dialog" :visible="show" no-close-on-backdrop>

    <template #modal-title="{}">
        <span class="schedule-header">Bulk Schedule</span>
    </template>

    <b-container fluid class="pb-4 schedule-body">

      <b-overlay :show="busy" opacity="0.5">

        <b-form>
          <b-form-group label="Frequency:">
            <b-form-select
              label-field="label"
              value-field="value"
              v-model="model.frequency"
              :options="ref.frequency"
              @change="setTimesOfDay()"
            ></b-form-select>
          </b-form-group>

          <!-- time of day -->
          <b-form-group id="time-ig" label="Time of Day:">
            <b-form-checkbox-group v-model="model.choice">
              <b-form-checkbox value="lately">Let Lately pick the times</b-form-checkbox>
            </b-form-checkbox-group>
            <div v-if="!model.choice.length">
              <TimeOfDay class="time-of-day" v-for="(t,idx) in model.times" :key="idx" :model="t" />
            </div>
          </b-form-group>

          <!-- start -->
          <b-form-group label="Starting:" v-slot="{ ariaDescribedby }">
            <b-form-datepicker id="example-datepicker" v-model="model.start" :min="ref.minDate" class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group label="Post Order:" v-slot="{ ariaDescribedby }" >
            <b-form-radio-group
              :options="ref.order"
              v-model="model.order"
              :aria-describedby="ariaDescribedby"
              inline
            >
            </b-form-radio-group>
          </b-form-group>

        </b-form>

      </b-overlay>

    </b-container>

    <template #modal-footer="{}">
      <div class="schedule-footer text-center">
        <span>
          <b-button :disabled="busy" class="cancel" type="button" variant="outline-default" @click="cancel()">CANCEL</b-button>
          <b-button :disabled="busy" class="submit" type="button" variant="outline-primary" @click="submit()">SET SCHEDULE</b-button>
        </span>
      </div>
    </template>

  </b-modal>
</template>

<script>

import { actions } from '@/services/store'

import TimeOfDay from './TimeOfDay'
const moment = require('moment')

const DATE_FORMAT = 'YYYY-MM-DD'

const c = (val) => {
  const frags = val.split(' ')
  const number = frags[0]
  const intervals = frags[1]
  const interval = frags[2] == 'd'? 'day' : frags[2] === 'w'? 'week' : 'month'
  const label = intervals > 1? `${number} every ${intervals} ${interval}s` : `${number} per ${interval}`
  return {
    value: val,
    text: label,
    number: number,
    unit: frags[2],
    interval: frags[1]
  }
}

export default {
  name: 'ScheduleDialog',
  data() {
    return {
      busy: false,
      ref: {
        minDate: moment().format(DATE_FORMAT),
        frequency:[
          c('1 1 d'),
          c('2 1 d'),
          c('3 1 d'),
          c('4 1 d'),
          c('6 1 d'),
          c('8 1 d'),
          c('10 1 d'),
          c('1 1 w'),
          c('2 1 w'),
          c('3 1 w'),
          c('1 2 w'),
          c('1 3 w'),
          c('1 1 m'),
          c('1 6 d'),
          c('1 8 d'),
          c('1 10 d'),
          c('1 12 d')
        ],
        order: [{
          text:'In Order',
          value: 'ordered'
        },{
          text:'Randomize',
          value:'random'
        }],
      },
      model: {
        times:[],
        choice:[],
        frequency:'1 1 d',
        order: 'ordered',
        start: moment().add(1,'day').format(DATE_FORMAT),
      },
    }
  },
  props: {
    dashboard: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean
    },
    channels: {
      type: Array,
      required: true
    },
    content: {
      type: Array,
      required: true
    },
    selections: {
      type: Array,
      required: true
    }
  },
  created() {
    this.setTimesOfDay()
  },
  methods: {

    setOrder(order) {
      this.model.order = [order]
    },

    entryFor(time,hour,minute) {
      const now = moment()
      if ( time ) {
        hour = moment(time).hour()
        return {
          hour: (hour>12? hour-12:hour).toString(),
          minute: moment(time).format('mm'),
          meridian: hour == 24? 'AM' : hour > 11? 'PM' : 'AM',
          time: now.toISOString(),
          id: Math.random(),
        }
      } else return {
        time: now.toISOString(),
        hour: now.hour(hour).format('h'),
        minute: now.minute(minute).format('mm'),
        meridian: now.hour() > 11? 'PM' : 'AM',
        id: Math.random(),
      }
    },
    setTimesOfDay() {

      this.model.times = []
      const frequency = this.ref.frequency.find((f)=>{ return f.value === this.model.frequency })

      // populate defaults
      if (frequency.number == '1') {
        this.model.times.push.apply(this.model.times,[
          this.entryFor(false,'11','00')])
      } else if (frequency.number == '2') {
        this.model.times.push.apply(this.model.times,[
          this.entryFor(false,'11','00'),
          this.entryFor(false,'18','00')])
      } else if (frequency.number == '3') {
        this.model.times.push.apply(this.model.times,[
          this.entryFor(false,'11','00'),
          this.entryFor(false,'15','00'),
          this.entryFor(false,'19','00')])
      } else if (frequency.number == '4') {
        this.model.times.push.apply(this.model.times,[
          this.entryFor(false,'9','00'),
          this.entryFor(false,'12','00'),
          this.entryFor(false,'15','00'),
          this.entryFor(false,'18','00')])
      } else if (frequency.number == '6') {
        this.model.times.push.apply(this.model.times,[
          this.entryFor(false,'9','00'),
          this.entryFor(false,'11','00'),
          this.entryFor(false,'13','00'),
          this.entryFor(false,'15','00'),
          this.entryFor(false,'17','00'),
          this.entryFor(false,'19','00')])
      } else if (frequency.number == '8') {
        this.model.times.push.apply(this.model.times,[
          this.entryFor(false,'9','00'),
          this.entryFor(false,'10','00'),
          this.entryFor(false,'11','00'),
          this.entryFor(false,'12','00'),
          this.entryFor(false,'13','00'),
          this.entryFor(false,'14','00'),
          this.entryFor(false,'15','00'),
          this.entryFor(false,'16','00')])
      } else if (frequency.number == '10') {
        this.model.times.push.apply(this.model.times,[
          this.entryFor(false,'8','00'),
          this.entryFor(false,'9','00'),
          this.entryFor(false,'10','00'),
          this.entryFor(false,'11','00'),
          this.entryFor(false,'12','00'),
          this.entryFor(false,'13','00'),
          this.entryFor(false,'14','00'),
          this.entryFor(false,'15','00'),
          this.entryFor(false,'16','00'),
          this.entryFor(false,'17','00')])
      }

    },
    cancel() {
      this.$emit('on-action',{action:'schedule-cancel'})
    },
    async submit() {

      try {

        // selected frequency
        const frequency = this.ref.frequency.find((f)=>{ return f.value === this.model.frequency })

        const options = {
          status: 'D',
          unit: frequency.unit,
          number: parseInt(frequency.number),
          interval: parseInt(frequency.interval),
          startingDate: moment(this.model.start,DATE_FORMAT).toISOString(),
          channels: this.channels.map((ch)=>{ return {
            type:ch.type,
            channel:ch.channel,
            dashboard: this.dashboard._id
          }}),
          posts: this.selections.length? this.selections : this.content.map((p)=>p._id)
        }

        if ( this.model.order === 'random' ) {
          options.randomize = true
        }

        if ( this.model.choice.length ) {
          options.pickForMe = true
        }

        // setup times
        const now = moment()
        options.timeOfDay = this.model.times.map((t)=>{
          const hour = parseInt(t.hour) + (t.meridian==='PM'? 12:0)
          now.hour(hour)
          now.minute(parseInt(t.minute))
          return now.toISOString()
        })

        this.busy = true
        const rawPosts = this.selections.length? this.content.filter((p)=>{return this.selections.includes(p._id)}) : this.content.slice()
        const resp = await actions.scheduleContent( this.dashboard._id, options, rawPosts )
        if ( resp.scheduleCount ) this.$toasted.success(`Scheduled ${resp.scheduleCount} Posts`)
        if ( resp.invalidCount ) this.$toasted.success(`Unable to scheduled ${resp.invalidCount} Posts`)
        this.$emit('on-action',{action:'schedule-complete'})

      } catch( err ) {
        console.error(err)
        this.$toasted.error(err.message)
      } finally {
        this.busy = false
      }

    }
  },

  components: {
    TimeOfDay
  }

}
</script>

<style lang="scss" >

.schedule-dialog {

  border-radius: 20px;

  .schedule-header {
    font-size: 24px;
    font-weight: bold;
    margin: 30px 0px 24px 0px;
  }

  .schedule-body {

    .col-model-label {
      font-size: 15px;
      color: #2D2D2D;
    }

    .time-of-day {
      margin: 16px 0px 24px 0px;
    }

  }

  .schedule-footer {
    margin: 30px;
    button {
      font-size: 12px;
      font-weight: bold;
      width: 160px;
      margin-left: 22px;
      border-radius: 10px;
    }
    .cancel {
      border: 2px solid lightgrey;
    }
    .submit {
      background-color: #008CFF;
      border-color: #008CFF;
      color: white;
    }
  }

  .modal-footer {
    text-align: center;
    padding: 0px;
  }

}

</style>
